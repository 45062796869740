import React from "react";

const HomeConfirmation = (props) => {
    return (
        <div className="modal-container">
            <div
                className="main-modal delete-modal"
                style={{
                    ...props.styles,
                    maxWidth: "400px",
                    minWidth: "auto",
                    display: "block",
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mb20"
                    width="320"
                    height="160"
                    style={{
                        position: "relative",
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}
                >
                    <g fill="none" fill-rule="evenodd">
                        <path d="M0 0H320V160H0z"></path>
                        <path
                            fill="#4F3DD1"
                            fill-rule="nonzero"
                            d="M30.719 25.734c-1.909-13.052-.016-21.015 5.677-23.886 8.541-4.308 16.065-.14 18.83 2.933 0 .586 14.904-1.111 16.15 8.673.832 6.522.832 11.038 0 13.546L30.72 25.734z"
                            transform="translate(107 1)"
                        ></path>
                        <path
                            d="M6.61 7.771C1.818 7.617-.37 6.037.05 3.031.473.026 2.659 1.606 6.61 7.771zM6.61 7.771C4.955 2.591 4.955 0 6.61 0c1.657 0 1.657 2.59 0 7.771z"
                            transform="translate(137 53)"
                            fill-rule="nonzero"
                            stroke="#100A40"
                            stroke-linecap="round"
                            stroke-width="1.25"
                        ></path>
                        <path
                            fill="#8172F1"
                            d="M36.785 60.19C20.988 63.157 9.538 71.715 2.434 85.867c-2.525 3.073-3.23 33.49-1.428 47.136 1.2 9.098 4.747 13.675 10.638 13.73V159h83.828v-6.223c6.933-2.32 10.4-7.996 10.4-17.028 0-13.548 7.441-52.737-15.9-65.663-15.56-8.617-22.865-12.211-21.917-10.78l-31.27.885z"
                            transform="translate(107 1)"
                        ></path>
                        <path
                            fill="#FAFAFA"
                            d="M32.775 26.051C28.258 26.391 26 28.588 26 32.64c0 4.052 3.088 6.371 9.263 6.958-.242 4.102.392 7.478 1.905 10.127v10.517L52.038 82l17.185-21.758-.671-9.84c1.285-4.029 1.928-7.996 1.928-11.903 0 0 7.52.015 7.52-5.86 0-5.873-6.263-6.588-7.52-6.588-1.257 0 1.576-11.106-10.553-10.44-2.318-.95-4.875-7.05-19.067-3.525-5.8 2.35-8.495 7.005-8.085 13.965z"
                            transform="translate(107 1)"
                        ></path>
                        <path
                            fill="#AEA3FD"
                            d="M36.942 49.358C35.647 46.823 35 43.443 35 39.218 35 32.881 46.907 30 53.292 30c6.384 0 17.109 2.748 17.62 8.118.341 3.58-.314 7.552-1.965 11.917C64.885 56.012 59.569 59 53 59c-6.57 0-11.922-3.214-16.058-9.642z"
                            transform="translate(107 1)"
                        ></path>
                        <path
                            stroke="#100A40"
                            stroke-linecap="round"
                            stroke-width="1.25"
                            d="M53.5 25L53.5 30M41 29c1.192-.889 2.858-1.185 5-.889M39 21c1.668-1.778 4.001-2.37 7-1.778M64 29c-1.192-.889-2.858-1.185-5-.889M66 21c-1.668-1.778-4.001-2.37-7-1.778M30 31c1.704.442 2.704 1.442 3 3M73 31c-1.704.442-2.704 1.442-3 3"
                            transform="translate(107 1)"
                        ></path>
                        <path
                            fill="#FAFAFA"
                            d="M70 119L70 136 83 129.363 83 119zM46 145.792c12.568-3.994 19.234-6.592 20-7.792v13c-4.911-.42-8.65-.963-11.214-1.63-2.564-.668-5.493-1.86-8.786-3.578z"
                            transform="translate(107 1)"
                        ></path>
                        <path
                            stroke="#100A40"
                            stroke-linecap="round"
                            stroke-width="1.25"
                            d="M25 102L25 121 82 118.809"
                            transform="translate(107 1)"
                        ></path>
                        <path
                            stroke="#100A40"
                            stroke-linecap="round"
                            stroke-width="1.25"
                            d="M81.987 97L83 129.768c-8.03 4.15-13.543 6.976-16.54 8.478-19.172 9.61-35.287 11.844-54.46 7.909M60 151c20.226 1 32.226 1.25 36 .75M34 3c-5 5.39-6.25 13.056-3.75 23M55 4.488C64.164 3.231 69.164 4.401 70 8M35 39c.205 11.54 5.872 18.207 17 20M36.557 49L36.443 60M52 82l17-22M56 32c1.84 1.934 3.84 3.267 6 4M70 25c5.333.488 8 2.654 8 6.5s-2.515 6.012-7.545 6.5"
                            transform="translate(107 1)"
                        ></path>
                        <path
                            stroke="#100A40"
                            stroke-linecap="round"
                            stroke-width="1.25"
                            d="M39 13c-4.166 2.96-6.25 7.391-6.25 13.291 0 0-7.245.769-6.723 7.239.348 4.313 3.451 6.47 9.308 6.47"
                            transform="translate(107 1)"
                        ></path>
                    </g>
                </svg>
                <div className="modal-header">
                    <h2 className="title">{props.headingText}</h2>
                </div>
                {props.children}
                <button
                    className="button buttonPrimary"
                    onClick={props.close}
                    style={{
                        paddingLeft: "2em",
                        paddingRight: "2em",
                        marginLeft: "auto",
                        marginRight: "auto",
                        // marginRight: "1em"
                    }}
                >
                    Okay
                </button>
            </div>
        </div>
    );
};

export default HomeConfirmation;
