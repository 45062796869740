import service from "../util/axiosConfig";
import { getDateDelta } from "./functions";
import { changeDateFormat } from "./date";

export const initialState = {
    uuid: undefined,
    agentUuid: undefined,
    user: undefined,
    d_id_upload: undefined,
    row: undefined,
    leadsPage: {
        leads: undefined,
    },
    membersPage: {
        members: undefined,
        floatBalance: undefined,
        memberSupport: undefined,
    },
    Authorization: undefined,
    alertStatus: {
        isOpen: false,
        type: "success",
        message: "",
        duration: 4000,
    },
    adminMembers: undefined,
    agent: undefined,
    verifyPhone: false,
    masterPolicy: undefined,
    formLoader: { state: "", component: "" },
    claims: {
        claimFromUrl: undefined,
        claimsData: null,
    },
    loginTime: null,
    subDomainInfo: {
        idealSessionTimeout: 0,
        titleBar: {
            text: "",
            favicon: "#",
        },
        siteLogo: "#",
        thirdPartyServices: {
            google: false,
            microsoft: false,
            sentry: false,
            yellowMessenger: false,
            hotjar: false,
        },
        // theme: {
        //     primary: "#4f3dd1",
        //     filter_primary:
        //         "invert(28%) sepia(67%) saturate(3124%) hue-rotate(223deg) brightness(73%) contrast(160%)",
        //     lead_table_button: "#8172f1",
        //     checkbox: "#5668d1",
        // },
    },
    leadInfo: {
        clientLogo: null,
        agentLogo: null,
    },
    config: null,
};

const replaceWithDash = (text, replacement = "-") => {
    return text?.length ? text : replacement;
};

const reducer = (state, action) => {
    switch (action.type) {
        case "set-id-upload":
            return { ...state, d_id_upload: action.payload };

        case "set-leadInfo":
            return {
                ...state,
                leadInfo: action.leadInfo,
            };
        case "set-config":
            return { ...state, config: action.config };
        case "set-subDomainInfo":
            let subDomainInfo = action.subDomainInfo;
            sessionStorage.setItem(
                "subDomainInfo",
                JSON.stringify(subDomainInfo)
            );
            return { ...state, subDomainInfo: subDomainInfo };
        case "set-authorization":
            localStorage.setItem("Authorization", action.data);
            let tempLoginTime = null;
            if (
                state.loginTime === null &&
                JSON.parse(localStorage.getItem("loginTime")) === null
            ) {
                tempLoginTime = new Date();
            } else {
                tempLoginTime =
                    state.loginTime ||
                    JSON.parse(localStorage.getItem("loginTime"));
            }
            localStorage.setItem("loginTime", JSON.stringify(tempLoginTime));
            return {
                ...state,
                Authorization: action.data,
                loginTime: tempLoginTime,
            };
        case "set-uuid":
            sessionStorage.setItem("uuid", action.uuid);
            return { ...state, uuid: action.uuid };
        case "set-verifyPhone":
            sessionStorage.setItem("verifyPhone", action.verifyPhone);
            return { ...state, verifyPhone: action.verifyPhone };
        case "set-user":
            console.log(action, "dispatched");
            sessionStorage.setItem(
                "user",
                JSON.stringify({
                    ...action.payload,
                    actualUserType:
                        state?.user?.actualUserType ||
                        action?.payload?.actualUserType ||
                        action?.payload?.userType,
                })
            );

            ///////////----- chat bot user setup ----////////

            // Hotjar

            let hotJar = false;
            let hotjarSetUser = setInterval(() => {
                if (hotJar) {
                    var userId = action.payload.uid || null;
                    window.hj("identify", userId, {
                        ...action.payload,
                    });
                    clearInterval(hotjarSetUser);
                } else {
                    hotJar = window.hj;
                }
            }, 1000);

            return {
                ...state,
                user: {
                    ...action.payload,
                    actualUserType:
                        state?.user?.actualUserType ||
                        action?.payload?.actualUserType ||
                        action?.payload?.userType,
                },
            };

        case "set-agentUuid":
            sessionStorage.setItem("agentUuid", action.agentUuid);
            return { ...state, agentUuid: action.agentUuid };
        case "set-userType":
            sessionStorage.setItem(
                "user",
                JSON.stringify({
                    ...state.user,
                    userType: action.userType,
                })
            );

            return {
                ...state,
                user: {
                    ...state.user,
                    userType: action.userType,
                },
            };
        case "set-lead":
            sessionStorage.setItem("lead", JSON.stringify(action.payload));
            return { ...state, lead: action.payload };
        case "set-row":
            sessionStorage.setItem("lead", JSON.stringify(action.payload));
            return { ...state, row: action.payload };
        case "reset-credentials":
            console.log("resetting creds");
            localStorage.removeItem("Authorization");
            sessionStorage.removeItem("uuid");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("leads");
            sessionStorage.removeItem("lead");
            sessionStorage.removeItem("verifyPhone");
            sessionStorage.removeItem("requestedSite");

            if (window?.YellowMessengerPlugin) {
                window.YellowMessengerPlugin.init({
                    payload: {
                        user: {
                            personName: "bot",
                            uuid: "none",
                            email: "none",
                        }, //res.data.response.user.email,
                        token: "none",
                    },
                });
                window.YellowMessengerPlugin.closeBot();
            }

            return { ...initialState, subDomainInfo: state.subDomainInfo };
        case "set-members":
            console.log(action, "Filtering members");
            let members = action.value?.map((d) => {
                if (!d.dob) {
                    return { ...d, dob: "-" };
                }
                // if (d.dob.length === 10) return d;
                //const date = convertToYYYY(new Date(d.dob));
                return {
                    ...d,
                };
            });

            members = members?.map((member) => {
                return {
                    ...member,
                    exitDate: member?.dateOfResigning || member?.policyEndDate,
                };
            });

            console.log(members, "members");

            return {
                ...state,
                membersPage: {
                    ...state.membersPage,
                    members: members ? members : state.membersPage.members,
                    floatBalance:
                        typeof action?.floatBalance !== "undefined"
                            ? action?.floatBalance
                            : state.membersPage.floatBalance,
                    memberSupport:
                        action?.memberSupport ||
                        state.membersPage.memberSupport,
                },
            };
        case "reset-members":
            return {
                ...state,
                membersPage: {
                    ...state.membersPage,
                    members: undefined,
                    floatBalance: undefined,
                    memberSupport: undefined,
                },
            };
        case "set-member-support":
            const memberSupport = {
                ...action.payload,
                sumInsured: action.payload?.sumInsured?.sort(),
            };
            return {
                ...state,
                membersPage: {
                    ...state.membersPage,
                    memberSupport: memberSupport,
                },
            };
        case "reset-member-support":
            return {
                ...state,
                membersPage: { ...state.membersPage, memberSupport: undefined },
            };
        case "set-member-policy":
            return {
                ...state,
                membersPage: { ...state.membersPage, policy: action.policy },
            };
        case "reset-member-policy":
            return {
                ...state,
                membersPage: { ...state.membersPage, policy: undefined },
            };
        case "set-leads":
            return { ...state, leadsPage: { leads: action.value } };
        case "delete":
            return {
                ...state,
                [`${action.page}Page`]: {
                    [action.page]: [
                        ...state[`${action.page}Page`][action.page],
                    ].filter((d) => d[action.key] !== action[action.key]),
                },
            };
        case "deleteMembers":
            service.delete("MEMBER_UPDATE", {
                ...action.data,
                uuid: state.uuid,
            });
            console.log("setmembers", action.data);
            return state;
        case "updateMember":
            console.log("deleting", action.data);
            service.put("MEMBER_UPDATE", action.data);
            return state;
        case "setAlert":
            return {
                ...state,
                alertStatus: {
                    ...action?.payload,
                },
            };
        case "set-admin-members":
            console.log("setting admin members", action.value);
            const adminMem = action.value?.map((d) => {
                // if (d?.dob?.length === 10) return d;
                // if (d?.startDate?.length === 10) return d;
                //const date = convertToYYYY(new Date(d.dob));
                /*const date = changeDateFormat(
          "javascriptDateObject",
          "yyyy-mm-dd",
          new Date(d.dob)
        );*/
                let noOfDays = 0;
                let computeDays = 0;
                if (d?.dateOfResigning && d?.policyEndDate) {
                    console.log(
                        getDateDelta(d?.policyEndDate, d?.dateOfResigning),
                        d?.name
                    );
                    computeDays = getDateDelta(
                        d?.policyEndDate,
                        d?.dateOfResigning
                    );
                    if (
                        d.insurer === "BAJAJ" ||
                        d.insurer === "ICICI" ||
                        d.insurer === "AXA"
                    ) {
                        computeDays = computeDays + 1;
                    }
                    noOfDays = 365 - computeDays;
                } else if (d?.dateOfJoining && d?.policyEndDate) {
                    computeDays =
                        getDateDelta(d?.policyEndDate, d?.dateOfJoining) + 1;
                    noOfDays = computeDays;
                }

                let actualAmount = 0;
                if (d?.ghiAmount) {
                    actualAmount = (d?.ghiAmount / computeDays) * 365;
                }

                const startDate = d?.startDate || "-";
                // true if ensurer else admin
                // keeping random for now
                const sentOn = replaceWithDash(d?.sentOn, "-");
                let dataType = "admin";
                const storedUidsWithTimers =
                    JSON.parse(localStorage.getItem("uidsWithTimers")) || [];
                const allUids = storedUidsWithTimers.flatMap(
                    (group) => group.uids
                );

                if (!d?.enrollmentDone) {
                    dataType = "enrollmentpending";
                } else if (d?.sentOn) {
                    dataType = "insurer";
                } else if (allUids?.includes(d?.uid)) {
                    dataType = "processing";
                }

                const data = {
                    ...d,
                    startDate: startDate,
                    batch: replaceWithDash(d?.batch),
                    dateOfResigning: replaceWithDash(d?.dateOfResigning),
                    dateOfJoining: replaceWithDash(d?.dateOfJoining),
                    actualAmount: Math.ceil(actualAmount),
                    noOfDays: noOfDays,
                    sentOn: sentOn,
                    dataType: dataType,
                };

                if (!sentOn) {
                    delete data.batch;
                }

                return {
                    ...data,
                };
            });
            console.log("Setting Admin Member", adminMem);
            return { ...state, adminMembers: adminMem };
        case "set-agent":
            return { ...state, agent: action.agent };
        case "set-master-policy":
            return { ...state, masterPolicy: action.masterPolicy };
        case "set-form-loader":
            return { ...state, formLoader: action.formLoader };
        case "set-claims":
            return {
                ...state,
                claims: {
                    ...state.claims,
                    ...action.payload,
                },
            };
        default:
            console.log("hello");
            return state;
    }
};

export default reducer;
