import React, { useEffect } from "react";
import {
    EnrollmentState,
    Family,
    Gender,
    Member,
    MemberWithState,
    MultiStepFormState,
} from "../../types";

// following are images for edit and delete
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import AddMemberForm from "./AddMemberForm";
import { NewMember } from "./types";
import { MemberValidation } from "../../types/DTO/enrollmentGetDTO";
import { changeDateFormat } from "../../util/date";
import AddIcon from "@mui/icons-material/Add";

import "./Members.scss";
import getMemberAvatar from "../../util/function/getMemberAvatar";
import alert from "../../util/alerts";
import { checkFamilyValidation } from "../../util/function/memberValidation";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

interface Props {
    members: MemberWithState[];
    family?: Family;
    setMembers: (members: MemberWithState[]) => void;
    setMembersError: (membersError: string[]) => void;
    validation: MemberValidation;
    askRelationShip?: boolean;
    readOnly?: boolean;
    employeeReadOnly?: boolean;
    employeeReadOnlyMessage?: string;
    editOnly?: boolean;
    setShowAddButton?: (show: boolean) => void;
}

const Members: React.FC<Props> = ({
    members,
    family,
    setMembers,
    setMembersError,
    validation,
    askRelationShip = true,
    readOnly = false,
    employeeReadOnly = false,
    employeeReadOnlyMessage = "",
    editOnly = false,
    setShowAddButton,
}: Props) => {
    const [selectedMember, setSelectedMember] =
        React.useState<MemberWithState | null>(null);
    const [showAddMemberForm, setShowAddMemberForm] =
        React.useState<boolean>(false);

    const [familyLimit, setFamilyLimit] = React.useState<{
        numberOfParent: number;
        numberOfChild: number;
        spouse: number;
    }>({
        numberOfParent: 0,
        numberOfChild: 0,
        spouse: 0,
    });

    useEffect(() => {
        if (family?.employee) {
            setFamilyLimit((prev) => {
                return {
                    ...prev,
                    numberOfParent: family.numberOfParent,
                    numberOfChild: family.numberOfChild,
                    spouse: family.spouse || family.liveInPartner ? 1 : 0,
                };
            });

            members.forEach((member) => {
                if (
                    member.relationShip === "Spouse" &&
                    member.state !== "remove"
                ) {
                    setFamilyLimit((prev) => ({
                        ...prev,
                        spouse: prev.spouse - 1,
                    }));
                } else if (
                    member.relationShip === "Child" &&
                    member.state !== "remove"
                ) {
                    setFamilyLimit((prev) => ({
                        ...prev,
                        numberOfChild: prev.numberOfChild - 1,
                    }));
                } else if (
                    member.relationShip === "Parent" &&
                    member.state !== "remove"
                ) {
                    setFamilyLimit((prev) => ({
                        ...prev,
                        numberOfParent: prev.numberOfParent - 1,
                    }));
                } else if (
                    member.relationShip === "ParentInLaw" &&
                    member.state !== "remove"
                ) {
                    setFamilyLimit((prev) => ({
                        ...prev,
                        numberOfParent: prev.numberOfParent - 1,
                    }));
                }
            });
        }
    }, [family]);

    useEffect(() => {
        if (
            Object.values(familyLimit).every(
                (value: number | undefined) => value !== undefined && value <= 0
            )
        ) {
            if (setShowAddButton) {
                setShowAddButton(false);
            }
        } else {
            if (setShowAddButton) {
                setShowAddButton(true);
            }
        }
    }, [familyLimit]);

    const updateFamilyLimit = (relationShip: string, operation: string) => {
        if (operation === "add") {
            switch (relationShip) {
                case "Spouse":
                    if (familyLimit.spouse > 0) {
                        setFamilyLimit((prev) => ({
                            ...prev,
                            spouse: prev.spouse - 1,
                        }));
                    }
                    break;
                case "Child":
                    if (familyLimit.numberOfChild > 0) {
                        setFamilyLimit((prev) => ({
                            ...prev,
                            numberOfChild: prev.numberOfChild - 1,
                        }));
                    }

                    break;
                case "Parent":
                case "ParentInLaw":
                    if (familyLimit.numberOfParent > 0) {
                        setFamilyLimit((prev) => ({
                            ...prev,
                            numberOfParent: prev.numberOfParent - 1,
                        }));
                    }
                    break;
                default:
                    break;
            }
        } else {
            switch (relationShip) {
                case "Spouse":
                    setFamilyLimit((prev) => ({
                        ...prev,
                        spouse: prev.spouse + 1,
                    }));
                    break;
                case "Child":
                    setFamilyLimit((prev) => ({
                        ...prev,
                        numberOfChild: prev.numberOfChild + 1,
                    }));
                    break;
                case "Parent":
                case "ParentInLaw":
                    setFamilyLimit((prev) => ({
                        ...prev,
                        numberOfParent: prev.numberOfParent + 1,
                    }));
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        setMembersError([
            ...(checkFamilyValidation(family || null, members).length > 0
                ? checkFamilyValidation(family || null, members)
                : []),
            ...(validateMembersAge().length > 0 ? validateMembersAge() : []),
            ...(validateSpouseGender() ? [validateSpouseGender() || ""] : []),
        ]);
    }, [members]);

    // find relation ship using relationship and gender
    const getRelationShip = (
        member: MemberWithState | Member,
        family: Family | null
    ) => {
        switch (member.relationShip) {
            case "Spouse":
                return family?.liveInPartner &&
                    member.displayRelationship === "LiveInPartner"
                    ? "LiveInPartner"
                    : member.gender === "Male"
                    ? "Husband"
                    : "Wife";
            case "Child":
                return member.gender === "Male" ? "Son" : "Daughter";
            case "Parent":
                return member.gender === "Male" ? "Father" : "Mother";
            case "ParentInLaw":
                return member.gender === "Male" ? "FatherInLaw" : "MotherInLaw";
            default:
                return "Self";
        }
    };

    // Add member function
    const addMember = (member: NewMember) => {
        updateFamilyLimit(member.relationShip, "add");
        setMembers([
            ...members,
            {
                ...member,
                state: "add",
                displayRelationship: getRelationShip(member, family || null),
            },
        ]);
    };

    // Edit member function
    const editMember = (member: MemberWithState, index: number) => {
        setMembers(
            members.map((m, i) => {
                if (i === index) {
                    return {
                        ...m,
                        ...member,
                        state: member.state === "add" ? "add" : "update",
                        displayRelationship: getRelationShip(
                            member,
                            family || null
                        ),
                    };
                }
                return m;
            })
        );
    };

    // Delete member function
    const deleteMember = (member: MemberWithState, index: number) => {
        updateFamilyLimit(member.relationShip, "remove");
        setMembers(
            members.map((m, i) => {
                if (i === index) {
                    return {
                        ...m,
                        state: "remove",
                    };
                }
                return m;
            })
        );
    };

    // function for validating members dob
    const validateMembersAge = () => {
        const validations = validation;

        let errors: string[] = [];

        let employeeJsDob = changeDateFormat(
            "yyyy-mm-dd",
            "javascriptDateObject",
            members.find((member) => member.relationShip === "Employee")?.dob ||
                ""
        ) as Date;

        members.forEach((member) => {
            // use valitaion used in AddMemberForm
            let jsDob = changeDateFormat(
                "yyyy-mm-dd",
                "javascriptDateObject",
                member.dob
            ) as Date;

            if (member.state !== "remove") {
                if (jsDob > new Date()) {
                    errors.push("Date of birth can't be in future");
                }
                if (
                    member.relationShip === "Spouse" &&
                    validations.Spouse &&
                    (new Date().getFullYear() - jsDob.getFullYear() <=
                        validations.Spouse?.minAge ||
                        new Date().getFullYear() - jsDob.getFullYear() >
                            validations.Spouse?.maxAge)
                ) {
                    errors.push(
                        `Spouse's age must be between ${validations.Spouse?.minAge} and ${validations.Spouse?.maxAge}`
                    );
                }
                if (
                    member.relationShip === "Child" &&
                    validations.Child &&
                    (new Date().getFullYear() - jsDob.getFullYear() <
                        validations.Child?.minAge ||
                        new Date().getFullYear() - jsDob.getFullYear() >
                            validations.Child?.maxAge)
                ) {
                    errors.push(
                        `Child's age must be between ${validations.Child?.minAge} and ${validations.Child?.maxAge}`
                    );
                }
                if (
                    member.relationShip === "Child" &&
                    validations.EmployeeChild &&
                    (jsDob.getFullYear() - employeeJsDob.getFullYear() >
                        validations.EmployeeChild?.maxAge ||
                        jsDob.getFullYear() - employeeJsDob.getFullYear() <=
                            validations.EmployeeChild?.minAge)
                ) {
                    errors.push(
                        `Child's age must be between ${validations.EmployeeChild?.minAge} and ${validations.EmployeeChild?.maxAge} of employee`
                    );
                }
                if (
                    member.relationShip === "Parent" &&
                    validations.Parent &&
                    (new Date().getFullYear() - jsDob.getFullYear() <
                        validations.Parent?.minAge ||
                        new Date().getFullYear() - jsDob.getFullYear() >
                            validations.Parent?.maxAge)
                ) {
                    errors.push(
                        `Parent's age must be between ${validations.Parent?.minAge} and ${validations.Parent?.maxAge}`
                    );
                }
                if (
                    (member.relationShip === "Parent" ||
                        member.relationShip === "ParentInLaw") &&
                    validations.EmployeeParent &&
                    (employeeJsDob.getFullYear() - jsDob.getFullYear() >
                        validations.EmployeeParent?.maxAge ||
                        employeeJsDob.getFullYear() - jsDob.getFullYear() <
                            validations.EmployeeParent?.minAge)
                ) {
                    errors.push(
                        `Parent's age must be between ${validations.EmployeeParent?.minAge} and ${validations.EmployeeParent?.maxAge} of employee`
                    );
                }
            }
        });
        return errors;
    };

    const validateSpouseGender = () => {
        const employee = members.find(
            (member) => member.relationShip === "Employee"
        );

        const spouse = members.find(
            (member) =>
                member.relationShip === "Spouse" && member.state !== "remove"
        );

        if (employee?.gender === spouse?.gender) {
            return "Employee and spouse can't be of same gender";
        }

        return false;
    };

    // Submit function
    const handleSubmit = (
        member: NewMember | MemberWithState,
        index: number
    ) => {
        if (selectedMember) {
            editMember(member as MemberWithState, index);
        } else {
            addMember(member as NewMember);
        }
        setSelectedMember(null);
        setShowAddMemberForm(false);
    };

    // open add member form
    const handleAddMemberClick = () => {
        setSelectedMember(null);
        setShowAddMemberForm(true);
    };

    return (
        <div className="membersComponent">
            {/* list of members */}
            {members.map(
                (member, index) =>
                    member.state !== "remove" && (
                        <div key={index} className="memberComponent_member">
                            <div className="memberComponent_memberImageContainer">
                                <img
                                    src={getMemberAvatar(member)}
                                    alt="member"
                                    className="memberComponent_memberImage"
                                />
                            </div>
                            <div className="memberComponent_memberDetailContainer">
                                <span className="membersComponent_memberName">
                                    {member.name}
                                </span>
                                <span className="membersComponent_memberDobRelatioship">
                                    {member.dobText ||
                                        (changeDateFormat(
                                            "yyyy-mm-dd",
                                            "dd-mmm-yyyy",
                                            member.dob
                                        ) as string)}
                                    {/* create a big dot */}
                                    <span
                                        style={{
                                            display: "inline-block",
                                            width: "5px",
                                            height: "5px",
                                            borderRadius: "50%",
                                            backgroundColor: "#D3D3D3",
                                        }}
                                        className="mr5 ml5"
                                    ></span>
                                    {getRelationShip(member, family || null)}
                                </span>
                            </div>
                            <div className="memberComponent_memberButtons">
                                {!readOnly &&
                                    (member.relationShip !== "Employee" ||
                                        (member.relationShip === "Employee" &&
                                            !employeeReadOnly)) && (
                                        <>
                                            <EditIcon
                                                onClick={() => {
                                                    setSelectedMember(member);
                                                    setShowAddMemberForm(true);
                                                }}
                                                className="membersComponent_memberEdit pointer"
                                                fontSize="small"
                                            />
                                        </>
                                    )}
                                {!readOnly &&
                                    member.relationShip === "Employee" &&
                                    employeeReadOnly &&
                                    employeeReadOnlyMessage && (
                                        <>
                                            <Tooltip
                                                title={employeeReadOnlyMessage}
                                            >
                                                <InfoIcon />
                                            </Tooltip>
                                        </>
                                    )}
                                {
                                    // not for employee
                                    member.relationShip !== "Employee" &&
                                        !member.enrolmentLock &&
                                        !readOnly && (
                                            <CancelIcon
                                                onClick={() =>
                                                    deleteMember(member, index)
                                                }
                                                className="membersComponent_memberDelete pointer"
                                                fontSize="small"
                                            />
                                        )
                                }
                            </div>
                        </div>
                    )
            )}

            {/* add member button */}
            {!readOnly && !editOnly && (
                <button
                    onClick={handleAddMemberClick}
                    className="membersComponent_addMemberButton"
                >
                    <AddIcon fontSize="large" />
                    Add Member
                </button>
            )}
            {showAddMemberForm && (
                <AddMemberForm
                    member={selectedMember}
                    members={members}
                    family={family}
                    index={
                        selectedMember ? members.indexOf(selectedMember) : -1
                    }
                    memberValidation={validation}
                    askRelationShip={askRelationShip}
                    onSubmit={handleSubmit}
                    close={() => {
                        setSelectedMember(null);
                        setShowAddMemberForm(false);
                    }}
                    // onCancel={() => {setSelectedMember(null); setShowAddMemberForm(false)}}
                />
            )}
        </div>
    );
};

export default Members;
