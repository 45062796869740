import NotFound from "../NotFound";

import { useStateValue } from "../../util/stateProvider";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";

const AuthorizedRoute = ({ authorized, migrate = false, ...props }) => {
    //const allowed = ["employee", "employer", "admin", "broker"];
    //if (!authorized) return <Navigate to={redirectPath} />;
    const { policyDto } = useSelector((state) => state.home);
    const [{ user, uuid, config }] = useStateValue();
    const defaultRoutes = {
        ADMIN: "/leads",
        BROKER: "/leads",
        EMPLOYEE: "/home",
        EMPLOYER: "/home",
        USER: "/home",
        AGENT: "/home",
    };

    const screenCreator = () => {
        console.log("screen creator", user);
        if (!localStorage.getItem("Authorization")) {
            sessionStorage.setItem("requestedSite", window.location.pathname);
            return <NotFound status={403} redirectPath="/login" />;
            //return <Navigate to={"/login"} />;
        } else if (user && !authorized.includes(user?.userType.toLowerCase())) {
            let temp = sessionStorage.getItem("requestedSite");
            sessionStorage.removeItem("requestedSite");
            return (
                <NotFound
                    status={403}
                    redirectPath={temp || defaultRoutes[user?.userType]}
                />
            );
        } else if (user) {
            return props.children;
        }
    };
    if (user?.isMigrated && migrate) {
        window.location.pathname = "/crm";
    } else if (
        (user?.userType === "EMPLOYEE" ||
            user?.userType === "EMPLOYER" ||
            user?.userType === "USER") &&
        uuid
            ? Array.isArray(policyDto?.d_home_policies) && config
            : true
    ) {
        return screenCreator();
    } else {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                }}
            >
                <CircularProgress color="neutral" />
            </Box>
        );
    }
};

export default AuthorizedRoute;
