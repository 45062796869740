import React, { useState } from "react";
import "./addon.scss";
import {
    Chip,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import HomeConfirmation from "./HomeConfirmation";
import alert from "../../util/alerts";
import AddonSteps from "./AddonSteps";
import { useEffect } from "react";
import { ADDONS_POPUP_GET, ADDONS_EMPLOYEE_GET } from "../../util/newconfig";
import advService from "../../util/advService";
import { useNavigate } from "react-router-dom";
import copyFunction from "../../util/function/copyFunction";
import GenericModalContainer from "../../components/Modals/GenericModalContainer";
const CopyIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/general/copy.svg";

function Addon({ config, className, phoneRequired = false }) {
    // const [popup, setPopup] = useState(false);
    const navigate = useNavigate();
    const [tncOpen, setTncOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const [tncData, setTncData] = useState();
    const [tncConfig, setTncConfig] = useState();

    useEffect(() => {
        setTncData({
            steps: config?.whatsCovered || config?.howToUse || [],
            buttonText: config?.buttonText,
            buttonClick: config?.buttonClick,
            params: config?.params || {
                code: { display: "IMT20", action: "copy" },
            },
            title: config?.title,
            popupHeading: config?.popupHeading,
            uid: config?.uid,
        });
    }, [config]);

    const [styles, setStyles] = useState();

    const handleClick = () => {
        console.log(tncData);
        let urlConfig = { ...ADDONS_EMPLOYEE_GET };
        urlConfig.url += tncData?.uid;
        advService(urlConfig, {}, (res) => {
            // window.open(res.data.response.url, "_blank");
            if (res?.data?.success) {
                const response = res?.data?.response;

                setTncData({
                    ...tncData,
                    params: JSON.parse(response?.partnerCoupons?.params),
                });
            }
        });
    };

    useEffect(() => {
        console.log("configStyles", config);
        setStyles({
            ...(config.titleColor && { title: { color: config.titleColor } }),
            container: {
                background: config?.background,
                height: config?.isEmployee ? "168px" : "200px",
            },
            button: config.button,
            button2: config.button2,
            bgImage: {
                bottom: config?.isEmployee ? "20px" : "50%",
                ...(!config?.isEmployee && { transform: "translateY(50%)" }),
                ...(!config?.isEmployee && { width: "100px" }),
            },
        });
    }, [config]);

    useEffect(() => {
        setTncConfig({
            ...config,
            styles: styles,
        });
    }, [styles]);

    return (
        <>
            <div
                className={`addon pr35 pl23 pt23 ${
                    config.isEmployee ? "pb20" : "pb15"
                } ${className ? className : ""}`}
                style={styles?.container}
            >
                {config?.isEmployee ? (
                    <h1
                        className="addon_text fontColor_dark fontSize_20"
                        style={styles?.title}
                    >
                        {config?.title}
                    </h1>
                ) : (
                    <h2
                        className="addon_text fontColor_dark fontSize_18"
                        style={styles?.title}
                    >
                        {config?.title}
                    </h2>
                )}
                {
                    <p className="addon_subtext fontColor_dark">
                        {config?.subText}
                    </p>
                }
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        width: "70%",
                    }}
                    className="addon_button_container"
                >
                    {config?.buttonText && (
                        <button
                            className="addon_button"
                            style={styles?.button}
                            onClick={() => {
                                if (config.popup) {
                                    if (!config?.isEmployee) {
                                        let urlConfig = { ...ADDONS_POPUP_GET };
                                        urlConfig.url +=
                                            JSON.parse(
                                                sessionStorage.getItem("user")
                                            ).uuid +
                                            "/" +
                                            config.uid;
                                        advService(urlConfig, {}, (res) => {
                                            if (res.data.success) {
                                                setTncOpen(true);
                                            }
                                        });
                                    } else {
                                        handleClick();
                                        setTncOpen(true);
                                    }
                                } else {
                                    if (
                                        phoneRequired &&
                                        !JSON.parse(
                                            sessionStorage.getItem("user")
                                        ).phoneVerified
                                    ) {
                                        setTncOpen(true);
                                    } else {
                                        config?.cta();
                                    }
                                }
                            }}
                        >
                            {config?.buttonText}
                        </button>
                    )}
                    {config?.button2 && (
                        <button
                            className="addon_button"
                            style={styles?.button2}
                            onClick={() => {
                                if (config.popup) {
                                    if (!config?.isEmployee) {
                                        let urlConfig = { ...ADDONS_POPUP_GET };
                                        urlConfig.url +=
                                            JSON.parse(
                                                sessionStorage.getItem("user")
                                            ).uuid +
                                            "/" +
                                            config.uid;
                                        advService(urlConfig, {}, (res) => {
                                            if (res.data.success) {
                                                setTncOpen(true);
                                            }
                                        });
                                    } else {
                                        handleClick();
                                        setTncOpen(true);
                                    }
                                } else {
                                    if (
                                        phoneRequired &&
                                        !JSON.parse(
                                            sessionStorage.getItem("user")
                                        ).phoneVerified
                                    ) {
                                        setTncOpen(true);
                                    } else {
                                        if (config?.cta2) {
                                            config.cta2();
                                        } else {
                                            config?.cta();
                                        }
                                    }
                                }
                            }}
                        >
                            {config?.button2Text}
                        </button>
                    )}
                </div>
                <img
                    src={config?.bgImage}
                    className={`addon_background ${config?.bgImageClass || ""}`}
                    style={styles?.bgImage}
                />
                {config?.isEmployee && config?.brandImage && (
                    <div className="addon_partner">
                        <img
                            src={config?.brandImage}
                            className="addon_partner_image"
                        />
                    </div>
                )}
                {config?.tag && (
                    <Chip
                        label={config?.tag}
                        size="small"
                        color="primary"
                        className="addon_chip fontSize_12"
                    />
                )}
            </div>
            {/* {popup && buttonConfirmation && (
        <HomeConfirmation close={closePopup} headingText={buttonConfirmation} />
      )} */}
            <AddonSteps
                config={tncConfig}
                data={tncData}
                open={tncOpen}
                handleClose={() => {
                    setTncOpen(false);
                }}
                phoneRequired={phoneRequired}
            />
        </>
    );
}

export default Addon;
