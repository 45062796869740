import React from "react";
import "./Steps.scss";

function Steps({ data, className = "" }) {
    return (
        <div className={`steps ${className}`}>
            {data?.map((step, i) => (
                <div className="steps_step mb10" key={`steps_${step}_${i}`}>
                    <b>{i + 1}</b>: <div className="steps_step_text ml10">
                    {step}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Steps;
