import React, { useEffect } from "react";
import "./google-translate.scss";

function useGoogleTranslateScript() {
    useEffect(() => {
        const addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: "en",
                    includedLanguages: "en,gu,hi,ta,te,mr,ml,bn,pa,or,kn",
                },
                "google_translate_element"
            );
        };
    }, []);
}

const GoogleTranslate = () => {
    useGoogleTranslateScript();

    return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
