import AddonConfigType from "../../types/addonConfig";
import surgicalCare from "../../assets/images/addons/surgicalCare.png";
import nursing_care from "../../assets/images/addons/nursing_care.png";
import physiotherapy from "../../assets/images/addons/physiotherapy.png";
import Prystine_logo from "../../assets/images/addons/Prystine_logo.png";
import seva_logo from "../../assets/images/addons/seva_logo.png";
const healthiansBrandImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/healthiansBrand.svg";
const pharmeasyBrandImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/pharmeasyBrand.svg";
const myDentalPlanBrandImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/myDentalPlanBrand.svg";
const mfineBrandImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/mfineBrand.svg";
const tataOneMgBrandImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/tataOneMgBrand.svg";
const manahWellnessBrandImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/manahLogo.png";
const redcliffelabsBrandImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Redcliffe+logo.png";
const goqiiBrandImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/Goqii.svg";
const cultBrandImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/Cult.fit+logo.svg";
const mamilyBrandImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/Mamily+logo.png";

const dental =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/dental.svg";
const pharmacy =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/pharmacy.svg";
const dr =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/dr.svg";
const test =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/test.svg";
const mentalHealth =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/feature_MENTAL_WELLNESS_PSYCHIATRIC_TREATMENT.png";
const fitness =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/Goqii+illustration.svg";
const cult_bgLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/Cult+illustration.svg";
const hra_bgLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/HRA+Card+illustration.svg";
const mamily_bgLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/Mamily.png";
const goqii_bgLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/Goquii.png";
const drOffline_bgLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/Inperson+consutlations.svg";
const opdCashless_bgLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/All+cashless.svg";
const opdReimbursement_bgLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/addon_icons/Reimburse+healthcare.svg";

export const serviceToStyle = {
    dr: {
        isEmployee: {
            background: "#FEF6B2",
            bgImage: dr,
            button: {
                color: "#6D6201",
                background: "white",
            },
        },
        isEmployer: {
            background: "#FEF6B2",
            bgImage: dr,
            button: {
                color: "white",
                background: "#6D6201",
            },
        },
    },
    test: {
        isEmployee: {
            background: "#A3FFEE",
            bgImage: test,
            button: {
                color: "#054C5E",
                background: "white",
            },
        },
        isEmployer: {
            background: "#A3FFEE",
            bgImage: test,
            button: {
                color: "white",
                background: "#054C5E",
            },
        },
    },
    dental: {
        isEmployee: {
            background: "#FFEDCA",
            bgImage: dental,
            button: {
                color: "#82590B",
                background: "white",
            },
        },
        isEmployer: {
            background: "#FFEDCA",
            bgImage: dental,
            button: {
                color: "white",
                background: "#82590B",
            },
        },
    },
    pharmacy: {
        isEmployee: {
            background: "#9FC6FF",
            bgImage: pharmacy,
            button: {
                color: "#082E65",
                background: "white",
            },
        },
        isEmployer: {
            background: "#9FC6FF",
            bgImage: pharmacy,
            button: {
                color: "white",
                background: "#082E65",
            },
        },
    },
    mentalHealth: {
        isEmployee: {
            background: "#E2DEFF",
            bgImage: mentalHealth,
            button: {
                color: "#3E3177",
                background: "white",
            },
        },
        isEmployer: {
            background: "#9FC6FF",
            bgImage: mentalHealth,
            button: {
                color: "white",
                background: "#3E3177",
            },
        },
    },
    surgicalCare: {
        isEmployee: {
            background: "#E5F3F9",
            bgImage: surgicalCare,
            button: {
                color: "#3E3177",
                background: "white",
            },
        },
        isEmployer: {
            background: "#E5F3F9",
            bgImage: surgicalCare,
            button: {
                color: "white",
                background: "#3E3177",
            },
        },
    },
    nurse: {
        isEmployee: {
            background: "#ffeaf1",
            bgImage: nursing_care,
            button: {
                color: "#3E3177",
                background: "white",
            },
        },
        isEmployer: {
            background: "#ffeaf1",
            bgImage: nursing_care,
            button: {
                color: "white",
                background: "#3E3177",
            },
        },
    },
    physio: {
        isEmployee: {
            background: "#E5EAFB",
            bgImage: physiotherapy,
            button: {
                color: "#3E3177",
                background: "white",
            },
        },
        isEmployer: {
            background: "#E5EAFB",
            bgImage: physiotherapy,
            button: {
                color: "white",
                background: "#3E3177",
            },
        },
    },
    fitness: {
        isEmployee: {
            background: "#D9E7EF",
            bgImage: fitness,
            button: {
                color: "#054C5E",
                background: "white",
            },
        },
        isEmployer: {
            background: "#D9E7EF",
            bgImage: fitness,
            button: {
                color: "white",
                background: "#3E3177",
            },
        },
    },
    goqii: {
        isEmployee: {
            background: "#D9E7EF",
            bgImage: goqii_bgLogo,
            button: {
                color: "#054C5E",
                background: "white",
            },
        },
        isEmployer: {
            background: "#D9E7EF",
            bgImage: goqii_bgLogo,
            button: {
                color: "white",
                background: "#3E3177",
            },
        },
    },
    mamily: {
        isEmployee: {
            background: "#FFE5E4",
            bgImage: mamily_bgLogo,
            button: {
                color: "#054C5E",
                background: "white",
            },
        },
        isEmployer: {
            background: "#FFE5E4",
            bgImage: mamily_bgLogo,
            button: {
                color: "white",
                background: "#3E3177",
            },
        },
    },
    cult: {
        isEmployee: {
            background: "#E6E5FD",
            bgImage: cult_bgLogo,
            button: {
                color: "white",
                background: "#560b7F",
            },
        },
        isEmployer: {
            background: "#E6E5FD",
            bgImage: cult_bgLogo,
            button: {
                color: "white",
                background: "#560b7F",
            },
        },
    },
    d_hra_config: {
        isEmployee: {
            background: "#D5E8F1",
            bgImage: hra_bgLogo,
            button: {
                color: "#4e017a",
                background: "white",
            },
        },
        isEmployer: {
            background: "#D5E8F1",
            bgImage: hra_bgLogo,
            button: {
                color: "white",
                background: "#4e017a",
            },
        },
    },
    drOffline: {
        isEmployee: {
            background: "#E5EAFB",
            bgImage: drOffline_bgLogo,
            button: {
                color: "#1C0D5F",
                background: "white",
            },
        },
        isEmployer: {
            background: "#E5EAFB",
            bgImage: drOffline_bgLogo,
            button: {
                color: "white",
                background: "#1C0D5F",
            },
        },
    },
    opdCashless: {
        isEmployee: {
            background: "#E5F3F9",
            bgImage: opdCashless_bgLogo,
            button: {
                color: "#082E65",
                background: "white",
            },
        },
        isEmployer: {
            background: "#E5F3F9",
            bgImage: opdCashless_bgLogo,
            button: {
                color: "white",
                background: "#082E65",
            },
        },
    },
    opdReimbursement: {
        isEmployee: {
            background: "#A3FFEE",
            bgImage: opdReimbursement_bgLogo,
            button: {
                color: "#082E65",
                background: "white",
            },
        },
        isEmployer: {
            background: "#A3FFEE",
            bgImage: opdReimbursement_bgLogo,
            button: {
                color: "white",
                background: "#082E65",
            },
        },
    },
};

const config: { [key: string]: AddonConfigType } = {
    opd: {
        title: undefined,
        buttonText: undefined,
        background: "#A3FFEE",
        bgImage: undefined,
        brandImage: undefined,

        partner: undefined,
        isEmployee: true,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    mfine: {
        title: undefined,
        buttonText: undefined,
        background: "#A3FFEE",
        bgImage: undefined,
        brandImage: mfineBrandImage,

        partner: undefined,
        isEmployee: true,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    healthians: {
        title: undefined,
        buttonText: undefined,
        background: "#A3FFEE",
        bgImage: undefined,
        brandImage: healthiansBrandImage,

        partner: undefined,
        isEmployee: true,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    redcliffelabs: {
        title: undefined,
        buttonText: undefined,
        background: "#A3FFEE",
        bgImage: undefined,
        brandImage: redcliffelabsBrandImage,

        partner: undefined,
        isEmployee: true,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    tataOneMg: {
        title: undefined,
        buttonText: undefined,
        background: "#9FC6FF",
        bgImage: undefined,
        brandImage: tataOneMgBrandImage,

        partner: undefined,
        isEmployee: undefined,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    pharmeasy: {
        title: undefined,
        buttonText: undefined,
        background: "#9FC6FF",
        bgImage: undefined,
        brandImage: pharmeasyBrandImage,

        partner: undefined,
        isEmployee: undefined,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    myDentalPlan: {
        title: undefined,
        buttonText: undefined,
        background: "#FFEDCA",
        bgImage: undefined,
        brandImage: myDentalPlanBrandImage,

        partner: undefined,
        isEmployee: undefined,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    manahWellness: {
        title: undefined,
        buttonText: undefined,
        background: "#E2DEFF",
        bgImage: undefined,
        brandImage: manahWellnessBrandImage,

        partner: undefined,
        isEmployee: undefined,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    pristyn: {
        title: undefined,
        buttonText: undefined,
        background: "#E5F3F9",
        bgImage: undefined,
        brandImage: Prystine_logo,

        partner: undefined,
        isEmployee: undefined,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    sevaProNurse: {
        title: undefined,
        buttonText: undefined,
        background: "#E2DEFF",
        bgImage: undefined,
        brandImage: seva_logo,

        partner: undefined,
        isEmployee: undefined,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    sevaProPhysio: {
        title: undefined,
        buttonText: undefined,
        background: "#E2DEFF",
        bgImage: undefined,
        brandImage: seva_logo,

        partner: undefined,
        isEmployee: undefined,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    goqii: {
        title: undefined,
        buttonText: undefined,
        background: "#D9E7EF",
        bgImage: undefined,
        brandImage: goqiiBrandImage,

        partner: undefined,
        isEmployee: undefined,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: false,
        cta: () => {
            window.open("https://dev-imt.goqii.com/homepage");
        },
    },
    cult: {
        title: undefined,
        buttonText: undefined,
        background: "#E6E5FD",
        bgImage: undefined,
        brandImage: cultBrandImage,

        partner: undefined,
        isEmployee: true,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    mamily: {
        title: undefined,
        buttonText: undefined,
        background: "#D9E7EF",
        bgImage: undefined,
        brandImage: mamilyBrandImage,

        partner: undefined,
        isEmployee: undefined,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
    d_hra_config: {
        title: undefined,
        buttonText: undefined,
        background: "#D5E8F1",
        bgImage: undefined,
        brandImage: undefined,

        partner: undefined,
        isEmployee: true,
        howToUse: undefined,
        whatsCovered: undefined,
        params: undefined,
        uid: undefined,
        popupHeading: undefined,
        popup: true,
    },
};

export default config;
