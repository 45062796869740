export const timeSince = (date) => {
    if (typeof date !== "object") {
        date = new Date(date);
    }

    let seconds = Math.floor((new Date() - date) / 1000);
    let intervalType;

    let interval = Math.floor(seconds / 31536000);

    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
        intervalType = "day";
    } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
            intervalType = "hour";
        } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
                intervalType = "minute";
            } else {
                interval = seconds;
                intervalType = "second";
            }
        }
    }

    const returnObj = {
        showAgo: true,
        timeSince: interval + " " + intervalType,
    };

    if (interval > 1 || interval === 0) {
        intervalType += "s";
    }

    if (interval >= 4 && intervalType === "days") {
        returnObj.showAgo = false;
        returnObj.timeSince = `${date.getDate()}/${
            date.getMonth() + 1
        }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
        return returnObj;
    }

    if (
        interval <= 0 &&
        (intervalType === "seconds" || intervalType === "second")
    ) {
        returnObj.showAgo = false;
        returnObj.timeSince = "just now";
    }

    return returnObj;
};

export const formatDate = (value) => {
    console.log("called ", value);
    let date = new Date(value);
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.toLocaleString("default", { year: "numeric" });
    return day + "-" + month.slice(0, 3) + "-" + year;
};

export const convertToDDMMYYYY = (date) => {
    console.log("raghvendra", date);
    date = date.trim();
    if (date && date !== "-") {
        let d = new Date(date);
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [day, month, year].join("-");
    }
};

// export const formatDate = (date) => {
//   return (new Date(date)).toLocaleDateString('en-GB', {
//     day: 'numeric', month: 'short', year: 'numeric'
//   }).replace(/ /g, '-')
// }

// export const reverseDateFormat = (date) => {
//   // convert 01-feb-2021 format to js date
// }

export const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

export const changeDateFormat = (format1, format2, date) => {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    let day, month, year;
    let splittedDate, splittedFormat1;
    if (format1 === "javascriptDateObject") {
        day = date.getDate();
        month = date.getMonth() + 1;
        year = date.getFullYear();
    } else {
        let seperator1 = date.includes("-") ? "-" : "/";
        splittedDate = date.split(seperator1);
        splittedFormat1 = format1.split(seperator1);
        for (let i = 0; i < 3; i++) {
            switch (splittedFormat1[i]) {
                case "dd":
                    day = parseInt(splittedDate[i]);
                    break;
                case "mm":
                    month = parseInt(splittedDate[i]);
                    break;
                case "yyyy":
                    year = parseInt(splittedDate[i]);
                    break;
                case "yy":
                    let temp = parseInt(splittedDate[i]);
                    if (temp < 25) {
                        year = parseInt("20" + splittedDate[i]);
                    } else {
                        year = parseInt("19" + splittedDate[i]);
                    }
                    break;
                case "mmm":
                    month =
                        months.findIndex(
                            (element) => element === splittedDate[i]
                        ) + 1;
                    break;
                default:
                    break;
            }
        }
    }
    let newDate = [];

    let splittedFormat2;
    if (format2 === "javascriptDateObject") {
        return new Date(year, month - 1, day);
    } else {
        let seperator2 = format2.includes("-") ? "-" : "/";
        splittedFormat2 = format2.split(seperator2);
        for (let i = 0; i < 3; i++) {
            switch (splittedFormat2[i]) {
                case "dd":
                    newDate[i] = day < 10 ? "0" + day : day;
                    break;
                case "mm":
                    newDate[i] = month < 10 ? "0" + month : month;
                    break;
                case "yyyy":
                    newDate[i] = year;
                    break;
                case "mmm":
                    newDate[i] = months[month - 1];
                    break;
                default:
                    break;
            }
        }
        console.log(newDate);
        console.log(newDate.join(seperator2));
        return newDate.join(seperator2);
    }
};
