import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";

import {
    getTrimmedText,
    signJwtAndRedirect,
    userType,
} from "../../util/functions";
import { useStateValue } from "../../util/stateProvider";
import Notifications from "./Notifications/Notifications";
import styles from "./topbar.module.scss";
const hospitalsIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/hospital.svg";
const plansIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/plans.svg";
const paymentsIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/payments.svg";
const membersIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/members.svg";
const homeIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/home.svg";
const claimsIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/claims.svg";
const adminIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/admin.svg";
const userIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/user_profile.svg";
const members_icon_active =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/members_active.svg";
const members_icon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/members.svg";
const leads_icon_active =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/leads_active.svg";
const leads_icon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/leads.svg";
const hospital_icon_active =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/hospital_active.svg";
const hospital_icon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/hospital.svg";
const home_icon_active =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/home_active.svg";
const home_icon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/home.svg";
const details_icon_active =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/details_active.svg";
const details_icon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/details.svg";
const admin_icon_active =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/admin_active.svg";
const admin_icon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/admin.svg";
const normalLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/brand_blue.svg";

function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            // console.log("Changed props Topbar:", changedProps);
        }
        prev.current = props;
    });
}

const Topbar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [{ user, row }, dispatch] = useStateValue();
    const [activeId, setactiveId] = useState(
        window.location.href.split("/")[
            window.location.href.split("/").length - 1
        ]
    );
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [topBarItems, setTopBarItems] = useState([]);
    const [topSubBarItems, setTopSubBarItems] = useState([]);
    const [companyName, setCompName] = useState("");
    const [showDropUp, setShowDropUp] = useState(false);
    const uuid = sessionStorage.getItem("uuid");

    useTraceUpdate(props);

    const logoutIcon = (
        <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.3916 8.80508L14.7489 5.0989C14.6592 5.00763 14.5527 4.93524 14.4355 4.88585C14.3184 4.83646 14.1927 4.81104 14.0659 4.81104C13.939 4.81104 13.8134 4.83646 13.6962 4.88585C13.579 4.93524 13.4726 5.00763 13.3829 5.0989C13.2017 5.2832 13.1 5.53317 13.1 5.79381C13.1 6.05445 13.2017 6.30442 13.3829 6.48872L15.3767 8.51726H4.82969C4.57351 8.51726 4.32783 8.6208 4.14668 8.8051C3.96554 8.9894 3.86377 9.23937 3.86377 9.50002C3.86377 9.76066 3.96554 10.0106 4.14668 10.1949C4.32783 10.3792 4.57351 10.4828 4.82969 10.4828H15.3767L13.3829 12.5113C13.2017 12.6956 13.1 12.9456 13.1 13.2062C13.1 13.4669 13.2017 13.7169 13.3829 13.9012C13.564 14.0855 13.8097 14.189 14.0659 14.189C14.3221 14.189 14.5678 14.0854 14.7489 13.9011L18.3916 10.1949C18.5728 10.0106 18.6746 9.76064 18.6746 9.5C18.6746 9.23935 18.5728 8.98939 18.3916 8.80508Z"
                fill="#0A2341"
            />
            <path
                d="M11.2691 15.069C11.013 15.069 10.7673 15.1725 10.5861 15.3568C10.405 15.5411 10.3032 15.7911 10.3032 16.0517V17.0345H1.93185V1.96552H10.3032V2.94828C10.3032 3.20892 10.405 3.45889 10.5861 3.64319C10.7673 3.82749 11.013 3.93103 11.2691 3.93103C11.5253 3.93103 11.771 3.82749 11.9522 3.64319C12.1333 3.45889 12.2351 3.20892 12.2351 2.94828V1.63793C12.2351 1.42283 12.1934 1.20984 12.1125 1.01112C12.0316 0.812399 11.913 0.631835 11.7636 0.479739C11.6141 0.327643 11.4366 0.206994 11.2413 0.12468C11.046 0.0423663 10.8366 0 10.6252 0H1.60988C1.18291 0 0.773433 0.172567 0.471523 0.479739C0.169612 0.786911 0 1.20352 0 1.63793V17.3621C0 17.7965 0.169612 18.2131 0.471523 18.5203C0.773433 18.8274 1.18291 19 1.60988 19H10.6252C10.8366 19 11.046 18.9576 11.2413 18.8753C11.4366 18.793 11.6141 18.6724 11.7636 18.5203C11.913 18.3682 12.0316 18.1876 12.1125 17.9889C12.1934 17.7902 12.2351 17.5772 12.2351 17.3621V16.0517C12.2351 15.7911 12.1333 15.5411 11.9522 15.3568C11.771 15.1725 11.5253 15.069 11.2691 15.069Z"
                fill="#0A2341"
            />
        </svg>
    );
    const getIcon = (icon) => {
        // console.log("icon is ", icon);
        switch (icon) {
            case "leads":
                return leads_icon;
            case "leads_active":
                return leads_icon_active;
            case "hospitals":
                return hospital_icon;
            case "hospitals_active":
                return hospital_icon_active;
            case "home":
                return home_icon;
            case "home_active":
                return home_icon_active;
            case "details":
                return details_icon;
            case "details_active":
                return details_icon_active;
            case "admin":
                return admin_icon;
            case "admin_active":
                return admin_icon_active;
            case "members":
                return members_icon;
            case "members_active":
                return members_icon_active;
            default:
                return details_icon;
        }
    };

    const getDesktopIcon = (icon) => {
        switch (icon) {
            case "admin":
                return adminIcon;
            case "claims":
                return claimsIcon;
            case "home":
                return homeIcon;
            case "members":
                return membersIcon;
            case "payments":
                return paymentsIcon;
            case "plans":
                return plansIcon;
            case "hospitals":
                return hospitalsIcon;
            default:
                return details_icon;
        }
    };

    useEffect(() => {
        let currentRoute = window.location.href.split("/");
        setactiveId(navigate.location.pathname.slice(1));
        // console.log(
        //   currentRoute,
        //   currentRoute[currentRoute.length - 1],
        //   navigate,
        //   "current route"
        // );
    }, [location]);

    // useEffect(()=>{
    //     let currentRoute = window.location.href.split("/")
    //     setactiveId(currentRoute[currentRoute.length-1])
    //     // // console.log(currentRoute, currentRoute[currentRoute.length-1], "current route")
    // })

    useEffect(() => {
        if (row?.companyName) {
            setCompName(row?.companyName);
        } else {
            if (
                sessionStorage.getItem("lead") &&
                sessionStorage.getItem("lead") !== "undefined"
            ) {
                const lead = JSON.parse(sessionStorage.getItem("lead"));
                // // console.log(lead, lead.companyName, "sessionLead")
                if (lead) {
                    setCompName(lead.companyName);
                }
            }
        }
    }, [row]);

    useEffect(() => {
        if (!activeId) {
            if (topBarItems?.length) {
                setactiveId(topBarItems[0]?.id);
            }
        }

        if (user) {
            const usrType = userType(user);
            let topItems = [...topBarItems];
            let subItems = [...topSubBarItems];
            // console.log(usrType, "topbarI");
            switch (usrType) {
                case "ADMIN":
                    topItems = [
                        {
                            name: "Leads",
                            link: "/leads",
                            id: "leads",
                            icon: "leads",
                        },
                        {
                            name: "Agents",
                            link: "/agents",
                            id: "agents",
                        },
                        {
                            name: "Insurers",
                            link: "/insurers",
                            id: "insurers",
                        },
                        {
                            name: "New Estimate",
                            link: "/newLead",
                            id: "newEst",
                        },
                    ];
                    subItems = [
                        // {
                        //     name : "Home",
                        //     link : "/dashboard",
                        //     id : "dashboard",
                        //     icon : "home"
                        // },
                        {
                            name: "Home",
                            link: "/home",
                            id: "home",
                            icon: "home",
                        },
                        {
                            name: "Members",
                            link: "/members",
                            id: "members",
                            icon: "members",
                        },
                        {
                            name: "Plans",
                            link: "/plans",
                            id: "plans",
                            icon: "plans",
                        },
                        {
                            name: "Hospitals",
                            link: "/hospitals",
                            id: "hospitals",
                            icon: "hospitals",
                        },
                        {
                            name: "Details",
                            link: "/details",
                            id: "details",
                            icon: "details",
                            user: "admin",
                        },
                        {
                            name: "Payments",
                            link: "/payments",
                            id: "payments",
                            user: isSmallScreen() ? "admin" : null,
                            icon: "payments",
                        },
                        {
                            name: "Admin",
                            link: "",
                            id: "admin",
                            icon: "admin",
                        },

                        // {
                        //   name: isSmallScreen() ? "Price Per Person" : "P.P.P",
                        //   link: "/endorsement",
                        //   id: "endorsements",
                        //   user: "admin",
                        // },
                        {
                            name: "Users",
                            link: "/users",
                            id: "users",
                            user: "admin",
                        },
                        {
                            name: "Claims",
                            link: "/claims",
                            id: "claims",
                            user: "admin",
                            icon: "claims",
                        },
                        {
                            name: "Actions",
                            link: "/admin",
                            user: "admin",
                        },
                    ];
                    break;
                case "CUSTOMER":
                    topItems = [
                        {
                            name: "Companies",
                            link: "/leads",
                            id: "leads",
                            icon: "leads",
                        },
                        {
                            name: "New Estimate",
                            link: "/newLead",
                            id: "newEst",
                        },
                    ];
                    subItems = [
                        {
                            name: "Home",
                            link: "/home",
                            id: "home",
                            icon: "home",
                        },
                        {
                            name: "Members",
                            link: "/members",
                            id: "members",
                            icon: "members",
                        },
                        {
                            name: "Plans",
                            link: "/plans",
                            id: "plans",
                            icon: "plans",
                        },
                        {
                            name: "Hospitals",
                            link: "/hospitals",
                            id: "hospitals",
                            icon: "hospitals",
                        },
                        {
                            name: "Payments",
                            link: "/payments",
                            id: "payments",
                            icon: "payments",
                        },
                        {
                            name: "Claims",
                            link: "/claims",
                            id: "claims",
                            user: "admin",
                            icon: "claims",
                        },
                    ];
                    // if (user.multipleLeads) {
                    //   topItems = [
                    //     {
                    //       name: "Companies",
                    //       link: "/leads",
                    //       id: "leads",
                    //       icon: "leads",
                    //     },
                    //     {
                    //       name: "New Estimate",
                    //       link: "/newLead",
                    //       id: "newEst",
                    //     },
                    //   ];
                    // } else {
                    //   topItems = [...subItems];
                    //   subItems = [];
                    // }
                    break;
                case "EMPLOYER":
                    subItems = [
                        {
                            name: "Home",
                            link: "/home",
                            id: "home",
                            icon: "home",
                        },

                        {
                            name: "Members",
                            link: "/members",
                            id: "members",
                            icon: "members",
                        },
                        {
                            name: "Plans",
                            link: "/plans",
                            id: "plans",
                            icon: "plans",
                        },
                        {
                            name: "Hospitals",
                            link: "/hospitals",
                            id: "hospitals",
                            icon: "hospitals",
                        },
                        {
                            name: "Payments",
                            link: "/payments",
                            id: "payments",
                            icon: "payments",
                        },
                        {
                            name: "Claims",
                            link: "/claims",
                            id: "claims",
                            user: "admin",
                            icon: "claims",
                        },
                        {
                            name: "Users",
                            link: "/users",
                            id: "users",
                            user: "admin",
                        },
                    ];
                    if (user.multipleLeads) {
                        topItems = [
                            {
                                name: "Companies",
                                link: "/leads",
                                id: "leads",
                                icon: "leads",
                            },
                        ];
                    } else if (!isSmallScreen()) {
                        topItems = [...subItems];
                        subItems = [];
                    }
                    break;
                case "USER":
                    subItems = [
                        {
                            name: "Home",
                            link: "/home",
                            id: "home",
                            icon: "home",
                        },
                        {
                            name: "Plans",
                            link: "/plans",
                            id: "plans",
                            icon: "plans",
                        },
                        {
                            name: "Hospitals",
                            link: "/hospitals",
                            id: "hospitals",
                            icon: "hospitals",
                        },
                        {
                            name: "Claims",
                            link: "/claims",
                            id: "claims",
                            user: "admin",
                            icon: "claims",
                        },
                        // {
                        //     name : "Admin",
                        //     link : "",
                        //     id : "admin",
                        //     icon : "admin"
                        // },
                        // {
                        //     name : "Claims",
                        //     link : "/claims",
                        //     id : "claims",
                        //     user : "admin"
                        // },
                        // {
                        //     name : "Actions",
                        //     link : "/admin",
                        //     user: "admin"
                        // },
                    ];
                    if (isSmallScreen()) {
                        topItems = [];
                    } else {
                        topItems = [...subItems];
                        subItems = [];
                    }
                    break;

                case "CUSTOM":
                    subItems = [
                        {
                            name: "Hospitals",
                            link: "/hospitals",
                            id: "hospitals",
                            icon: "hospitals",
                        },
                        {
                            name: "Admin",
                            link: "",
                            id: "admin",
                            icon: "admin",
                        },
                        {
                            name: "Claims",
                            link: "/claims",
                            id: "claims",
                            user: "admin",
                            icon: "claims",
                        },
                        {
                            name: "Actions",
                            link: "/admin",
                            user: "admin",
                        },
                    ];
                    if (!isSmallScreen()) {
                        topItems = [...subItems];
                        subItems = [];
                    }
                    break;
                default:
                    break;
            }

            setTopBarItems(topItems);
            setTopSubBarItems(subItems);
        }
    }, [user]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const rightCaret = (
        <svg
            width="10"
            height="17"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.221758 16.1595C-0.0739194 15.893 -0.0739194 15.4622 0.221758 15.1957L8.00573 8.17968L0.221758 1.16369C-0.0739194 0.897184 -0.0739194 0.466385 0.221758 0.199879C0.517436 -0.0666264 0.995391 -0.0666264 1.29107 0.199879L9.60971 7.69779C9.75718 7.83071 9.8313 8.00519 9.8313 8.17971C9.8313 8.35423 9.75718 8.52871 9.60971 8.66163L1.29107 16.1595C0.995391 16.426 0.517436 16.426 0.221758 16.1595Z"
                fill="#4F3DD1"
            />
        </svg>
    );

    const onLogout = () => {
        navigate("/logout");
    };

    const isPathValid = (items) => {
        if (
            navigate.location.pathname !== "/leads" &&
            navigate.location.pathname !== "/agents" &&
            navigate.location.pathname !== "/agents/users" &&
            navigate.location.pathname !== "/insurers"
        )
            return true;
        else return false;
    };

    const handleItemClick = (item) => {
        // console.log("topbar click ", item);
        if (item.id === "newEst") {
            signJwtAndRedirect("/newLead");
            // window.location.href = window.location.origin + "/newLead"
        } else if (item.id === "admin") {
            setShowDropUp(true);
        } else {
            setShowDropUp(false);
            setactiveId(item?.id);
            // console.log("setting id", item?.id, activeId);
            navigate(item?.link);
        }
    };

    const isSmallScreen = () => {
        return window.innerWidth < 767;
    };

    const handleLogoClick = () => {
        if (user?.personName && uuid) {
            navigate("/home");
        } else {
            navigate("/leads");
        }
    };

    return (
        <div>
            <div className={styles.topBarContainer}>
                <div className={`top-bar ${styles.topBar}`}>
                    <img
                        src={normalLogo}
                        alt=""
                        className={`logo-normal mr-5`}
                        style={{ width: "auto", height: "90%" }}
                        onClick={handleLogoClick}
                    />

                    {!isSmallScreen() ? (
                        <div
                            className={`linkItemsContainer ${styles.linkItemsContainer}`}
                        >
                            {!topSubBarItems?.length && companyName ? (
                                <>
                                    <div className={` ${styles.companyName} `}>
                                        <span className={styles.nameSm}>
                                            <>
                                                {getTrimmedText(
                                                    companyName,
                                                    10
                                                )}
                                            </>
                                            <span>{rightCaret}</span>
                                        </span>
                                        <span className={styles.nameFull}>
                                            <>
                                                {getTrimmedText(
                                                    companyName,
                                                    100
                                                )}
                                            </>
                                        </span>
                                    </div>
                                </>
                            ) : null}
                            {topBarItems.map(
                                (item, ind) =>
                                    item && (
                                        <div
                                            className={styles.linkItems}
                                            style={{
                                                color:
                                                    item.id === activeId
                                                        ? "#4f3dd1"
                                                        : "#919191",
                                            }}
                                            key={ind}
                                            onClick={() =>
                                                handleItemClick(item)
                                            }
                                        >
                                            <span>{item.name}</span>
                                        </div>
                                    )
                            )}
                        </div>
                    ) : null}
                    <div className={styles.actions}>
                        {isSmallScreen()
                            ? topBarItems?.map((item, ind) =>
                                  item?.icon ? (
                                      <img
                                          key={ind}
                                          src={getIcon(
                                              activeId === item?.id
                                                  ? `${item?.icon}_active`
                                                  : item?.icon
                                          )}
                                          alt={item?.name}
                                          width="24px"
                                          height="24px"
                                          onClick={() => handleItemClick(item)}
                                          style={{
                                              cursor: "pointer",
                                              marginRight: "1em",
                                          }}
                                      />
                                  ) : null
                              )
                            : null}
                        <Notifications />
                        <img
                            src={userIcon}
                            alt="user icon"
                            id="userIcon"
                            width="24px"
                            height="24px"
                            onClick={handleClick}
                            style={{ cursor: "pointer" }}
                        />
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            style={{ marginTop: "2rem" }}
                            className="menuUser"
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <span style={{ paddingLeft: "0.5em" }}>
                                Hi, {user?.personName}!
                            </span>
                            <MenuItem>
                                <Link to="/logout topbar_logout">
                                    <span className="mr-1 ">{logoutIcon}</span>{" "}
                                    Logout
                                </Link>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
            {/* {console.log(topSubBarItems.length, isSmallScreen(), "&&&&&&&&&&&&&&&&")}} */}
            {topSubBarItems?.length && !isSmallScreen() ? (
                <div className={styles.topSubBarContainer}>
                    {/* {console.log("****")} */}
                    {
                        <div
                            className={`${styles.topBar} ${styles.topBarSub} ${
                                isPathValid(topSubBarItems)
                                    ? styles.showBar
                                    : styles.hideBar
                            }`}
                        >
                            <div
                                className={`linkItemsContainer ${styles.linkItemsContainer} `}
                            >
                                {companyName ? (
                                    <>
                                        <div
                                            className={` ${styles.companyName} `}
                                        >
                                            <span className={styles.nameSm}>
                                                <>
                                                    {getTrimmedText(
                                                        companyName,
                                                        10
                                                    )}
                                                </>
                                                <span>{rightCaret}</span>
                                            </span>
                                            <span className={styles.nameFull}>
                                                <>
                                                    {getTrimmedText(
                                                        companyName,
                                                        100
                                                    )}
                                                </>
                                            </span>
                                        </div>
                                    </>
                                ) : null}
                                {topSubBarItems.map(
                                    (item, ind) =>
                                        item &&
                                        item.user !== "admin" &&
                                        item.name !== "Admin" && (
                                            <div
                                                className={` ${
                                                    styles.linkItems
                                                } ${
                                                    activeId === item?.id
                                                        ? styles.active
                                                        : ""
                                                }`}
                                                key={ind}
                                                onClick={() =>
                                                    handleItemClick(item)
                                                }
                                            >
                                                <img
                                                    src={getDesktopIcon(
                                                        item.icon
                                                    )}
                                                />
                                                <span>{item.name}</span>
                                            </div>
                                        )
                                )}
                                {topSubBarItems.map(
                                    (item, ind) =>
                                        item &&
                                        item.user !== "admin" &&
                                        item.name === "Admin" && (
                                            <div
                                                key={ind}
                                                className={`subbar ${
                                                    styles.linkItems
                                                } ${
                                                    activeId === item?.id
                                                        ? styles.active
                                                        : ""
                                                }`}
                                            >
                                                <div
                                                    className={`subbar ${styles.linkItems}`}
                                                    key={item?.id}
                                                >
                                                    <img
                                                        src={getDesktopIcon(
                                                            item.icon
                                                        )}
                                                    />
                                                    <span>{item.name}</span>
                                                </div>
                                                <div className="sideSubbarDropdown">
                                                    {topSubBarItems.map(
                                                        (item, ind) =>
                                                            item &&
                                                            item.user ===
                                                                "admin" && (
                                                                <div
                                                                    className={` ${
                                                                        styles.linkItems
                                                                    } ${
                                                                        activeId ===
                                                                        item?.id
                                                                            ? styles.active
                                                                            : ""
                                                                    }`}
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    key={ind}
                                                                    onClick={() =>
                                                                        handleItemClick(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <span>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )
                                                    )}
                                                </div>
                                            </div>
                                        )
                                )}
                            </div>
                        </div>
                    }
                </div>
            ) : null}
            {props.children}
            {isSmallScreen() ? (
                <div
                    className={`${styles.topBarContainer} ${
                        styles.bottomBarContainer
                    } ${
                        isPathValid(topSubBarItems)
                            ? styles.showBar
                            : styles.hideBar
                    }`}
                >
                    <div
                        className={`${styles.bottomBar} top-bar ${styles.topBar} `}
                    >
                        {topSubBarItems?.map((item, ind) =>
                            item?.user !== "admin" && item?.icon ? (
                                <div
                                    key={ind}
                                    className={styles.linkItems}
                                    onClick={() => handleItemClick(item)}
                                >
                                    <img
                                        src={getIcon(
                                            activeId === item?.id
                                                ? `${item?.icon}_active`
                                                : item?.icon
                                        )}
                                        alt={item?.name}
                                        width="24px"
                                        height="24px"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <span>{item?.name}</span>
                                </div>
                            ) : null
                        )}
                    </div>
                    <div
                        className={`${styles.dropUpContainer} ${
                            showDropUp ? styles.show : ""
                        }`}
                    >
                        <div className={styles.dropUp}>
                            <div
                                className={styles.slideBtn}
                                onClick={() => setShowDropUp(false)}
                            ></div>
                            {topSubBarItems?.map((item, ind) =>
                                item?.user === "admin" ? (
                                    <div
                                        className={` ${styles.linkItems} ${
                                            activeId === item?.id
                                                ? styles.active
                                                : ""
                                        }`}
                                        key={ind}
                                        onClick={() => handleItemClick(item)}
                                    >
                                        {item?.name}
                                    </div>
                                ) : null
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Topbar;
