import { Lead, User } from "../../types";

export const setSignIn = (
    authToken: string,
    user: User | null,
    lead: Lead | null,
    uuid: string | null,
    d_verify_phone: boolean,
    dispatch: any | null
) => {
    dispatch({
        type: "set-authorization",
        data: authToken,
    });
    dispatch({
        type: "set-user",
        payload: user,
    });
    dispatch({
        type: "set-agentUuid",
        agentUuid: user?.agentUuid,
    });

    if (uuid) {
        dispatch({
            type: "set-uuid",
            uuid: uuid,
        });
    }

    if (lead) {
        dispatch({
            type: "set-lead",
            payload: lead,
        });
    }

    dispatch({
        type: "set-verifyPhone",
        verifyPhone: d_verify_phone,
    });
    // if (d_verify_phone) {
    //     navigate && navigate("/login/onBoard");
    // } else {
    //     dispatch({
    //         type: "set-verifyPhone",
    //         verifyPhone: d_verify_phone,
    //     });
    // if (page === "additionalInfo") {
    //     signJwtAndRedirect("/additionalInfo");
    // } else if (page === "newEstimate") {
    //     signJwtAndRedirect("/newLead");
    // } else if (page === "estimate") {
    //     signJwtAndRedirect("/estimate");
    // } else if (
    //     (page === "memberDataUpload" ||
    //         page === "companyInfo" ||
    //         page === "invoice") &&
    //     lead
    // ) {
    //     if (page === "memberDataUpload") {
    //         signJwtAndRedirect(`/quote/${lead.uuid}`);
    //     } else if (page === "companyInfo") {
    //         signJwtAndRedirect(`/quote/${lead.uuid}/company`);
    //     } else {
    //         signJwtAndRedirect(`/quote/${lead.uuid}/invoice`);
    //     }
    // } else if (navigate && user) {
    //     if (page === "memberData") {
    //         navigate("/home");
    //     } else if (page === "leadView") {
    //         if (
    //             user.userType === "CUSTOMER" ||
    //             user.userType === "CUSTOM"
    //         ) {
    //             navigate("/leads");
    //         } else {
    //             navigate("/leads");
    //         }
    //     } else {
    //         navigate("/leads");
    //     }
    // }
};
// }
