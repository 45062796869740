import { configureStore } from "@reduxjs/toolkit";
import homeSlice from "./features/home/homeSlice";
import authSlice from "./features/auth/authSlice";
import claimSlice from "./features/claims/claimSlice";
import claimSliceOperationTeam from "./features/claims/claimSliceOperationTeam";
import configSlice from "./features/config/configSlice";
import kmdSlice from "./features/kmd/kmdSlice";

export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
    reducer: {
        home: homeSlice,
        auth: authSlice,
        config: configSlice,
        customerCare: claimSlice,
        operationTeam: claimSliceOperationTeam,
        kmd: kmdSlice,
    },
});
