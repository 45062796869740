import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import advService from "../../util/advService";
import { MICROSOFT_LOGIN } from "../../util/newconfig";
import "./microsoft.scss";
import alert from "../../util/alerts";
import checkResponseAndSetStep from "../../util/function/checkResponse";
import { AxiosResponse } from "axios";
import { LoginResponse } from "../../types/DTO/loginDTO";
import { JavaResponse } from "../../types";
import { useDispatch } from "react-redux";
import { setSignIn } from "../../util/function";
import { redirect } from "../../util/function/redirect";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../util/stateProvider";
const MicrosoftIcon = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/microsoft.svg";

/**
 * Renders a button which, when selected, will open a popup for login
 */
interface MicrosoftProps {
    autoLoad?: boolean;
    disabled?: boolean;
}

export const Microsoft = ({ autoLoad, disabled }:MicrosoftProps) => {
    const { instance } = useMsal();
    const reducerDispatch = useDispatch();
    const navigate = useNavigate();
    const [{ subDomainInfo }, dispatch] = useStateValue();


    const handleLogin = (loginType:string) => {
        if (loginType === "popup") {
            instance
                .acquireTokenPopup(loginRequest)
                .then((value) => {
                    console.log("microsoft", value);
                    advService(
                        MICROSOFT_LOGIN,
                        {
                            authId: value.accessToken,
                        },
                        async (res:AxiosResponse<JavaResponse<LoginResponse>>) => {
                            if (res.data.success) {
                                let newRes : boolean | AxiosResponse<JavaResponse<any>> = checkResponseAndSetStep(res, reducerDispatch);
                            
                            if (newRes) {
                                let response = newRes.data.response;
                                setSignIn(
                                    newRes.headers.authorization,
                                    response?.user || null,
                                    response.lead,
                                    response.page === "leadView"
                                        ? null
                                        : response.uuid ||
                                              response.user?.uuid ||
                                              null,
                                    response?.d_verify_phone,
                                    dispatch,
                                );
                                redirect(newRes,navigate);
                            }
                            } else {
                                alert("error", res.data.message);
                            }
                        }
                    );
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    return (
        <button
            className={`buttonWhite mt20 shadow pr10 pl10 microsoftLogin ${
                disabled ? "disabled" : ""
            }`}
            onClick={() => handleLogin("popup")}
            disabled={disabled}
        >
            <img src={MicrosoftIcon} alt="" className="microsoftLogin_icon" />
            Login with Microsoft
        </button>
    );
};
export default Microsoft;
