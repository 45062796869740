import { PayloadAction,createSlice } from "@reduxjs/toolkit";
import HomeDto from "../../types/DTO/homeDTO";



interface HomeState {
    homeDto: HomeDto | null;
    policyDto: any,
}

const initialState: HomeState = {
  homeDto: null,
  policyDto: null,
};

// create slice
const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setHomeDto: (state: HomeState, action: PayloadAction<HomeState['homeDto']>) => {
            state.homeDto = action.payload;
        },
        setPolicyDto: (state: HomeState, action: PayloadAction<HomeState['policyDto']>) => {
            state.policyDto = action.payload;
        },
    },
});

export const { setHomeDto ,setPolicyDto} = homeSlice.actions;

export default homeSlice.reducer;
