import React, { useEffect, useRef, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "./Dropdown.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
//import { useField } from "formik";

const CloseIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Modals_icons/Close.svg";

const Dropdown = (props) => {
    const [items, setItems] = useState(null);
    const [selectedItem, setSelectedItem] = useState("");
    const [showList, setShowList] = useState(false);
    const containerRef = useRef();

    //const [field, meta] = useField(props);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        if (props) {
            let itemsValue = props.items.map((i) => {
                if (i.id !== props.defaultVal) {
                    return { ...i, isSelected: false };
                } else {
                    //setSelectedItem({ ...i, isSelected: true });
                    setSelectedItem(i.id);
                    return { ...i, isSelected: true };
                }
            });
            //setSelectedItem(itemsValue?.[0]?.id || "");
            setItems(itemsValue);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props.items]);

    useEffect(() => {
        if (props.autocomplete) {
            if (props.showDropdown) {
                setShowList(true);
            } else {
                setShowList(false);
            }
        }
    }, [props.showDropdown]);

    const handleSelectChange = (item) => {
        const itemValues = items.map((i) =>
            i.id === item.id
                ? { ...i, isSelected: true }
                : { ...i, isSelected: false }
        );
        setItems(itemValues);
        //setSelectedItem({ ...item, isSelected: true });
        setSelectedItem(item.id);
        // console.log(item.id)
        props.change(item.id);
    };

    const handleClickOutside = (event) => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            setShowList(false);
            if (props.handleClickOutside) {
                console.log("handleing outside click in ");
                props.handleClickOutside();
            }
        }
    };

    const findStyles = () => {
        if (selectedItem && props.color && props.background) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].name === selectedItem.name) {
                    return {
                        background: props.background[i],
                        color: props.color[i],
                    };
                }
            }
        }
        return {};
    };

    /*return (
    <div
      ref={containerRef}
      className={
        (showList ? "custom-dd active " : " custom-dd ") +
        (props.disabled ? " not-allowed " : "") +
        props.className
      }
      style={props.autocomplete?{height:"auto"}:{}}
    >
      <div className={`dd-title ${props.autocomplete? "hide": ""}`}>{props.title}</div>
      <div
        className="dd-list-wrapper"
        onBlur={() => (showList ? setShowList(false) : null)}
        onClick={() => {
          if (!props.disabled) {
            setShowList((prev) => !prev);
          }
        }}
        style={props.autocomplete?{height:"auto"}:{}}
      >
        <div className={`${items && items.length > 1 ? "dd-selected":"dd-one-item"} ${props.autocomplete? "hide": ""}`} style={findStyles()}>
          {selectedItem && selectedItem.name}
        </div>
        {(items?.length > 1) &&
        <div className={`dd-list ${showList ? "show" : "hide"}`} style={props.autocomplete?{height:"auto", top:"-20px"}:{}}>
          {items &&
            items.map((i, ind) => {
              return (
                <div
                  key={i.id}
                  onClick={() => handleSelectChange(i)}
                  className={`item ${i.isSelected ? "itemSelected" : null}`}
                  style={props.color ? { color: props.color[ind] } : {}}
                >
                  {i.name}
                </div>
              );
            })}
        </div>}
      </div>
    </div>
  );*/
    const handleChange = (event) => {
        //setSelectedItem(event.target.value);
        let i;
        items.forEach((val) => {
            if (val.id === event.target.value) i = val;
        });
        console.log(i);
        handleSelectChange(i);
    };
    let label = {};
    if (props?.title) {
        label = {
            label: props?.title,
            labelId: props?.title,
        };
    } else {
        label = {
            displayEmpty: true,
            inputProps: { "aria-label": "Without label" },
        };
    }
    return (
        <FormControl
            fullWidth
            size={props.size}
            style={{
                ...(props?.className?.includes("topDropdown") && {
                    width: "auto",
                    height: props.size === "small" ? "40px" : "48px",
                    position: "relative",
                }),
                ...props?.style,
            }}
            focused={props?.focused}
        >
            {props?.title && (
                <InputLabel
                    id={
                        props.title + "" + props.size === "small"
                            ? "demo-select-small-label"
                            : ""
                    }
                    shrink={props.shrink}
                >
                    {props?.title}
                </InputLabel>
            )}
            {props?.haveCancelCross && selectedItem && (
                <IconButton
                    style={{
                        position: "absolute",
                        right: "30px",
                        top: props.size === "small" ? "5px" : "13px",
                        zIndex: 1,
                        cursor: "pointer",
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        setSelectedItem("");
                        props?.change("");
                        if (props?.onCancel) {
                            props?.onCancel();
                        }
                    }}
                >
                    <img src={CloseIcon} alt="cancel" />
                </IconButton>
            )}
            <Select
                //id={field.name}
                labelId={
                    props.size === "small" ? "demo-select-small-label" : ""
                }
                id={props.size === "small" ? "demo-select-small" : ""}
                className={
                    //(showList ? "custom-dd active " : " custom-dd ") +
                    (props.disabled ? " not-allowed " : "") +
                    (props?.variant === "standard"
                        ? "standard-noborder "
                        : "") +
                    props.className
                }
                MenuProps={{
                    disableScrollLock: true,
                }}
                disabled={props.disabled}
                onChange={handleChange}
                value={selectedItem}
                style={{ height: "100%", width: "auto" }}
                variant={props?.variant || "outlined"}
                InputProps={{
                    readOnly: props.readOnly ? true : false,
                }}
                {...label}
                onOpen={() => {
                    if (!props.disabled) {
                        props?.onOpen && props.onOpen();
                    }
                }}
                renderValue={(selected) => {
                    return (
                        <div>
                            {items?.find((item) => item.id === selected)?.name}
                            {selected.tag ? (
                                <span className="dropdownTag ml">
                                    {selected.tag}
                                </span>
                            ) : null}
                        </div>
                    );
                }}
            >
                {items &&
                    items.map((i) => (
                        <MenuItem value={i.id} key={i.id}>
                            {i.name}
                            {i.tag ? (
                                <span className="dropdownTag ml fontSize_12 pr10 pl10">
                                    {i.tag}
                                </span>
                            ) : null}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default Dropdown;
