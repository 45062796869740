const AdimnD = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/admin.svg";
const ClaimsD = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/claims.svg";
const HomeD = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/home.svg";
const HospitalD = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/hospital.svg";
const MembersD = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/members.svg";
const PaymentsD = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/payments.svg";
const PlansD = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/desktopIcons/plans.svg";
const ReportD = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/reports.png";
const DownArrow = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/down-arrow.png";
const ParamsD = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/params.svg";
const walletD = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/wallet.png"

const actionsM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/actions.svg";
const agentsM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/agents.svg";
const detailsM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/details.svg";
const insurerM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/insurer.svg";
const leadsM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/leads.svg";
const newEstimateM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/newEstimate.svg";
const profileM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/profile.svg";
const HospitalM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/mobileIcons/hospital.svg";
const claimReportM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/claim_report.png";
const appusageM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/app_usage.png";
const walletM = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/nav_icons/wallet.png"

const iconCreator = (val) => {
    console.log(val, "val");
    switch (val) {
        case "admin":
            return AdimnD;
        case "claims":
            return ClaimsD;
        case "home":
            return HomeD;
        case "hospitals":
            return HospitalD;
        case "members":
            return MembersD;
        case "payments":
            return PaymentsD;
        case "plans":
            return PlansD;
        case "actions":
            return actionsM;
        case "agents":
            return agentsM;
        case "details":
            return detailsM;
        case "insurer":
            return insurerM;
        case "leads":
            return leadsM;
        case "newEstimate":
            return newEstimateM;
        case "uploadHospital":
            return HospitalM;
        case "reports":
            return ReportD;
        case "claimreport":
            return claimReportM;
        case "appusage":
            return appusageM;
        case "arrowD":
            return DownArrow;
        case "params":
            return ParamsD;
        case "users" || "admin":
            return profileM;
        case "wallet":
            return walletD;
        default:
            return HomeD;
    }
};

export default iconCreator;
