import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";

//import advService from '../../util/advService';
import advService from "../../util/advService";
import { UNSUBSCRIBE_GET, SUBSCRIBE_GET } from "../../util/newconfig";


import "./Unsubscribe.scss";
const SUCCESS = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/loaderSuccess.svg";
const LOGO = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/brand_blue.svg";
const IMG = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/unsubscribe.svg";

const Unsubscribe = () => {
    const { refId } = useParams();
    const ref = useRef();
    const [state, setState] = useState(0);

    const handleClick = (values) => {
        console.log(values.unsub);
        if (values.unsub === "never") {
            setState(1);
            let config = { ...UNSUBSCRIBE_GET };
            config.url += `${refId}`;
            advService(config, {}, (_res) => {});
        } else if (values.unsub === "onceaweek") {
            setState(2);
        }
    };

    //handle subscribe again after unsubscribing
    const handleSubscribeAgain = () => {
        let config = { ...SUBSCRIBE_GET };
        config.url += `${refId}`;
        advService(config, {}, (_res) => {});
        setState(3);
    };

    return (
        <>
            {state === 0 ? (
                <div className="unsubscribe__container">
                    <div className="unsubscribe">
                        <img
                            className="unsubscribe__byeimage"
                            src={IMG}
                            alt="Bye"
                        />
                        <img
                            className="unsubscribe__logo mb30"
                            src={LOGO}
                            alt="logo"
                        />
                        <div className="unsubscribe__head">
                            <h1>Your opinion matters</h1>
                            <p>
                                Tell me how often do you want to hear from us:
                            </p>
                        </div>
                        <Formik
                            initialValues={{
                                unsub: "onceaweek",
                            }}
                            onSubmit={handleClick}
                            innerRef={ref}
                        >
                            {({ values }) => (
                                <Form>
                                    <p>
                                        <Field
                                            type="radio"
                                            id="onceaweek"
                                            name="unsub"
                                            value="onceaweek"
                                        />
                                        <label htmlFor="onceaweek">
                                            I love your educational emails
                                            simplifying insurance, but I would
                                            prefer to only hear from
                                            InsureMyTeam once a week.
                                        </label>
                                        <br />
                                    </p>
                                    <Field
                                        type="radio"
                                        id="never"
                                        name="unsub"
                                        value="never"
                                    />
                                    <label htmlFor="never">
                                        Unsubscribe — I love you guys, but I
                                        need a break (FYI, you'll still get
                                        emails related to your Policy).
                                    </label>
                                    <br />
                                    <button className="buttonPrimary ">
                                        {values?.unsub === "onceaweek"
                                            ? "Reduce Frequency"
                                            : "Confirm Unsubscribe"}
                                    </button>
                                </Form>
                            )}
                        </Formik>
                        <div class="unsubscribe__foot">
                            <p>
                                <strong>Do you know? : </strong>Our content and
                                insurance team on average spends 438 hours 27
                                minutes every month to curate emails for you.
                                But if you feel that this isn’t for you and want
                                to still unsubscribe, it’s absolutely cool.
                            </p>
                            <p>
                                If you like, you can follow us on{" "}
                                <a
                                    href="https://instagram.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Instagram
                                </a>{" "}
                                or{" "}
                                <a
                                    href="https://facebook.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Facebook
                                </a>{" "}
                                to not miss any update.
                            </p>
                        </div>
                    </div>
                </div>
            ) : state === 2 ? (
                <div className="unsubscribe__container">
                    <div
                        className="unsubscribe"
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            className="unsubscribe__logo mb30"
                            src={LOGO}
                            alt="logo"
                        />
                        <div className="circle">
                            <img src={SUCCESS} alt="" />
                        </div>
                        <h1>Email frequency reduced!</h1>
                        <p>Now you will receiving on monthly basis from us.</p>
                    </div>
                </div>
            ) : state === 1 ? (
                <div className="unsubscribe__container">
                    <div
                        className="unsubscribe"
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            className="unsubscribe__logo mb30"
                            src={LOGO}
                            alt="logo"
                        />
                        <div className="circle">
                            <img src={SUCCESS} alt="" />
                        </div>
                        <h1>Thanks for being with us</h1>
                        <p>
                            Now you won’t be recieving any email from us. You
                            can subscribe them again.
                        </p>
                        <button
                            className="buttonPrimary "
                            onClick={handleSubscribeAgain}
                        >
                            Subscribe again
                        </button>
                    </div>
                </div>
            ) : (
                <div className="unsubscribe__container">
                    <div
                        className="unsubscribe"
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            className="unsubscribe__logo mb30"
                            src={LOGO}
                            alt="logo"
                        />
                        <div className="circle">
                            <img src={SUCCESS} alt="" />
                        </div>
                        <h1>Thank You</h1>
                    </div>
                </div>
            )}
        </>
    );
};

export default Unsubscribe;
