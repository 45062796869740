// Add/Edit member form using formik. Name, Dob and relationship as  input fields
// Compare this function from src/components/Members/Members.tsx:
// use MyText component for name and dob fields. and Dropdown for relationship field

import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Family, Member, MemberWithState } from "../../types";
import Dropdown from "../Input/Dropdown";
import MyText from "../Input/MyText";
import { FormValues, NewMember } from "./types";
import { MemberValidation } from "../../types/DTO/enrollmentGetDTO";
import GenericModalContainer from "../Modals/GenericModalContainer";
import { changeDateFormat } from "../../util/date";
import relationShipMap, {
    RelationShipsMapFull,
} from "../../types/enums/relationShipMap";
import { Error } from "@mui/icons-material";

interface Props {
    members: MemberWithState[];
    member: MemberWithState | null;
    family?: Family;
    index: number;
    memberValidation: MemberValidation;
    askRelationShip?: boolean;
    onSubmit: (member: Member | NewMember, index: number) => void;
    close: () => void;
}

const AddMemberForm: React.FC<Props> = ({
    members,
    member,
    family,
    index,
    memberValidation,
    onSubmit,
    close,
}: Props) => {
    const [relationshipList, setRelationshipList] = React.useState<
        { id: string; name: string; gender: string; relationShip: string }[]
    >([]);
    const [gender, setGender] = React.useState<string>("");
    const [relationShip, setRelationShip] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");
    // function to get relationhip(child, parent, spouse...) using relationShip(son, daughter, ...)
    const getRelationShip = (relationShip: string) => {
        switch (relationShip) {
            case "Husband":
                return "Spouse";
            case "Wife":
                return "Spouse";
            case "LiveInPartner":
                return "Spouse";
            case "Son":
                return "Child";
            case "Daughter":
                return "Child";
            case "Father":
                return "Parent";
            case "Mother":
                return "Parent";
            case "FatherInLaw":
                return "ParentInLaw";
            case "MotherInLaw":
                return "ParentInLaw";
            default:
                return "Employee";
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        // create tests for dob to check whether the age is in range specified in memberValidation and message should be dependent on memberValidation
        dob: Yup.date()
            .required("Required")
            .test(
                "dobFuture",
                "DOB can't be in future",
                (value: any) => value <= new Date()
            )
            .test(
                "dobEmployee",
                `Employee age should be between ${memberValidation?.Employee?.minAge} and ${memberValidation?.Employee?.maxAge}`,
                (value: any) => {
                    if (
                        getRelationShip(relationShip) === "Employee" &&
                        memberValidation?.Employee
                    ) {
                        return (
                            value &&
                            value <=
                                new Date(
                                    new Date().getFullYear() -
                                        memberValidation.Employee?.minAge,
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    new Date().getHours()
                                ) &&
                            value >
                                new Date(
                                    new Date().getFullYear() -
                                        memberValidation.Employee?.maxAge,
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    new Date().getHours()
                                )
                        );
                    }
                    return true;
                }
            )
            .test(
                "dobSpouse",
                `Spouse${
                    family?.liveInPartner ? "/LiveInPartner" : ""
                } age should be between ${
                    memberValidation?.Spouse?.minAge
                } and ${memberValidation?.Spouse?.maxAge}`,
                (value: any) => {
                    if (
                        getRelationShip(relationShip) === "Spouse" &&
                        memberValidation?.Spouse
                    ) {
                        return (
                            value &&
                            value <=
                                new Date(
                                    new Date().getFullYear() -
                                        memberValidation.Spouse?.minAge,
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    new Date().getHours()
                                ) &&
                            value >
                                new Date(
                                    new Date().getFullYear() -
                                        memberValidation.Spouse?.maxAge,
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    new Date().getHours()
                                )
                        );
                    }
                    return true;
                }
            )
            .test(
                "dobEmployeeChild",
                `The difference of age between Employee and Child should be between ${memberValidation?.EmployeeChild?.minAge} and ${memberValidation?.EmployeeChild?.maxAge}`,
                (value: any) => {
                    if (
                        getRelationShip(relationShip) === "Child" &&
                        memberValidation?.EmployeeChild
                    ) {
                        let employee = members.find(
                            (member) => member.relationShip === "Employee"
                        );

                        return (
                            value &&
                            new Date(value).getFullYear() -
                                new Date(
                                    changeDateFormat(
                                        "yyyy-mm-dd",
                                        "javascriptDateObject",
                                        employee?.dob
                                    ) || 0
                                ).getFullYear() >=
                                memberValidation.EmployeeChild?.minAge &&
                            new Date(value).getFullYear() -
                                new Date(
                                    changeDateFormat(
                                        "yyyy-mm-dd",
                                        "javascriptDateObject",
                                        employee?.dob
                                    ) || 0
                                ).getFullYear() <=
                                memberValidation.EmployeeChild?.maxAge
                        );
                    }
                    return true;
                }
            )
            .test(
                "dobChild",
                `Child age should be between ${memberValidation?.Child?.minAge} and ${memberValidation?.Child?.maxAge}`,
                (value: any) => {
                    if (
                        getRelationShip(relationShip) === "Child" &&
                        memberValidation?.Child
                    ) {
                        return (
                            value &&
                            value <=
                                new Date(
                                    new Date().getFullYear() -
                                        memberValidation.Child?.minAge,
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    new Date().getHours()
                                ) &&
                            value >
                                new Date(
                                    new Date().getFullYear() -
                                        memberValidation.Child?.maxAge,
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    new Date().getHours()
                                )
                        );
                    }
                    return true;
                }
            )
            .test(
                "dobChildDays",
                `Child age should be more than ${memberValidation.Child?.minDays} age`,
                (value: any) => {
                    if (
                        getRelationShip(relationShip) === "Child" &&
                        memberValidation?.Child?.minDays
                    ) {
                        return (
                            value &&
                            new Date().getTime() - new Date(value).getTime() >=
                                memberValidation.Child?.minDays *
                                    24 *
                                    60 *
                                    60 *
                                    1000
                        );
                    }
                    return true;
                }
            )
            .test(
                "dobParent",
                `Parent age should be between ${memberValidation?.Parent?.minAge} and ${memberValidation?.Parent?.maxAge}`,
                (value: any) => {
                    if (
                        (getRelationShip(relationShip) === "Parent" ||
                            getRelationShip(relationShip) === "ParentInLaw") &&
                        memberValidation?.Parent
                    ) {
                        return (
                            value &&
                            value <=
                                new Date(
                                    new Date().getFullYear() -
                                        memberValidation.Parent?.minAge,
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    new Date().getHours()
                                ) &&
                            value >
                                new Date(
                                    new Date().getFullYear() -
                                        memberValidation.Parent?.maxAge,
                                    new Date().getMonth(),
                                    new Date().getDate(),
                                    new Date().getHours()
                                )
                        );
                    }
                    return true;
                }
            )
            .test(
                "dobEmployeeParent",
                `The difference of age between Employee and Parent should be between ${memberValidation?.EmployeeParent?.minAge} and ${memberValidation?.EmployeeParent?.maxAge}`,
                (value: any) => {
                    if (
                        (getRelationShip(relationShip) === "Parent" ||
                            getRelationShip(relationShip) === "ParentInLaw") &&
                        memberValidation?.EmployeeParent
                    ) {
                        // Find employee
                        let employee = members.find(
                            (m) => m.relationShip === "Employee"
                        );
                        return (
                            value &&
                            new Date(
                                changeDateFormat(
                                    "yyyy-mm-dd",
                                    "javascriptDateObject",
                                    employee?.dob
                                ) || 0
                            ).getFullYear() -
                                new Date(value).getFullYear() >=
                                memberValidation.EmployeeParent?.minAge &&
                            new Date(
                                changeDateFormat(
                                    "yyyy-mm-dd",
                                    "javascriptDateObject",
                                    employee?.dob
                                ) || 0
                            ).getFullYear() -
                                new Date(value).getFullYear() <=
                                memberValidation.EmployeeParent?.maxAge
                        );
                    }
                    return true;
                }
            ),
    });

    function checkParentLimit(
        members: MemberWithState[],
        family: Family
    ): boolean {
        const parentCount = members.filter(
            (m) => m.relationShip === "Parent" && m.state !== "remove"
        ).length;
        const parentInLawCount = members.filter(
            (m) => m.relationShip === "ParentInLaw" && m.state !== "remove"
        ).length;
        return parentCount + parentInLawCount < family?.numberOfParent;
    }
    // in useEffect, set relationshipList based on members and member
    useEffect(() => {
        let newRelationshipList: {
            id: string;
            name: string;
            gender: string;
            relationShip: string;
        }[] = [];
        if (member) {
            setRelationshipList([
                ...relationShipMap,
                ...(family?.liveInPartner
                    ? [
                          {
                              id: "LiveInPartner",
                              name: "LiveInPartner",
                              gender:
                                  members.find(
                                      (m) => m.relationShip === "Employee"
                                  )?.gender === "Male"
                                      ? "Female"
                                      : "Male",
                              relationShip: "LiveInPartner",
                          },
                      ]
                    : []),
                members.find((m) => m.relationShip === "Employee")?.gender ===
                "Male"
                    ? {
                          id: "Wife",
                          name: "Wife",
                          gender: "Female",
                          relationShip: "Spouse",
                      }
                    : {
                          id: "Husband",
                          name: "Husband",
                          gender: "Male",
                          relationShip: "Spouse",
                      },
            ]);

            setRelationShip(
                RelationShipsMapFull.find((r) => {
                    console.log(r);
                    return (
                        r.gender === member.gender &&
                        r.relationShip === member.relationShip
                    );
                })?.id || ""
            );

            setGender(member.gender);
        } else {
            if (
                !members.find(
                    (m) => m.relationShip === "Spouse" && m.state !== "remove"
                ) &&
                family?.spouse
            ) {
                if (
                    members.find((m) => m.relationShip === "Employee")
                        ?.gender === "Female"
                ) {
                    newRelationshipList.push({
                        id: "Husband",
                        name: "Husband",
                        gender: "Male",
                        relationShip: "Spouse",
                    });
                } else {
                    newRelationshipList.push({
                        id: "Wife",
                        name: "Wife",
                        gender: "Female",
                        relationShip: "Spouse",
                    });
                }
                if (family.liveInPartner) {
                    newRelationshipList.push({
                        id: "LiveInPartner",
                        name: "LiveInPartner",
                        gender:
                            members.find((m) => m.relationShip === "Employee")
                                ?.gender === "Female"
                                ? "Male"
                                : "Female",
                        relationShip: "LiveInPartner",
                    });
                }
            }

            // add children
            if (
                family?.numberOfChild &&
                family?.numberOfChild > 0 &&
                members.filter(
                    (m) => m.relationShip === "Child" && m.state !== "remove"
                ).length < family?.numberOfChild
            ) {
                newRelationshipList.push({
                    id: "Son",
                    name: "Son",
                    gender: "Male",
                    relationShip: "Child",
                });
                newRelationshipList.push({
                    id: "Daughter",
                    name: "Daughter",
                    gender: "Female",
                    relationShip: "Child",
                });
            }

            if (family?.parent) {
                // if family.parent 1 add only parents
                // if family.parent 2 add  parents in law
                // if family.parent 3 add parents and parents in law
                // if family.parent 4 add parents or parents in law
                // only add if not already added in members

                let father = {
                    id: "Father",
                    name: "Father",
                    gender: "Male",
                    relationShip: "Parent",
                };

                let mother = {
                    id: "Mother",
                    name: "Mother",
                    gender: "Female",
                    relationShip: "Parent",
                };

                let fatherInLaw = {
                    id: "FatherInLaw",
                    name: "FatherInLaw",
                    gender: "Male",
                    relationShip: "ParentInLaw",
                };

                let motherInLaw = {
                    id: "MotherInLaw",
                    name: "MotherInLaw",
                    gender: "Female",
                    relationShip: "ParentInLaw",
                };

                if (family.parent === 1) {
                    if (
                        !members.find(
                            (m) =>
                                m.relationShip === "Parent" &&
                                m.gender === "Male" &&
                                m.state !== "remove"
                        )
                    ) {
                        if (checkParentLimit(members, family)) {
                            newRelationshipList.push(father);
                        }
                    }

                    if (
                        !members.find(
                            (m) =>
                                m.relationShip === "Parent" &&
                                m.gender === "Female" &&
                                m.state !== "remove"
                        )
                    ) {
                        if (checkParentLimit(members, family)) {
                            newRelationshipList.push(mother);
                        }
                    }
                } else if (family.parent === 2) {
                    if (
                        !members.find(
                            (m) =>
                                m.relationShip === "ParentInLaw" &&
                                m.gender === "Male" &&
                                m.state !== "remove"
                        )
                    ) {
                        if (checkParentLimit(members, family)) {
                            newRelationshipList.push(fatherInLaw);
                        }
                    }

                    if (
                        !members.find(
                            (m) =>
                                m.relationShip === "ParentInLaw" &&
                                m.gender === "Female" &&
                                m.state !== "remove"
                        )
                    ) {
                        if (checkParentLimit(members, family)) {
                            newRelationshipList.push(motherInLaw);
                        }
                    }
                } else if (family.parent === 3) {
                    if (
                        !members.find(
                            (m) =>
                                m.relationShip === "Parent" &&
                                m.gender === "Male" &&
                                m.state !== "remove"
                        )
                    ) {
                        if (checkParentLimit(members, family)) {
                            newRelationshipList.push(father);
                        }
                    }

                    if (
                        !members.find(
                            (m) =>
                                m.relationShip === "Parent" &&
                                m.gender === "Female" &&
                                m.state !== "remove"
                        )
                    ) {
                        if (checkParentLimit(members, family)) {
                            newRelationshipList.push(mother);
                        }
                    }
                    if (
                        !members.find(
                            (m) =>
                                m.relationShip === "ParentInLaw" &&
                                m.gender === "Male" &&
                                m.state !== "remove"
                        )
                    ) {
                        if (checkParentLimit(members, family)) {
                            newRelationshipList.push(fatherInLaw);
                        }
                    }
                    if (
                        !members.find(
                            (m) =>
                                m.relationShip === "ParentInLaw" &&
                                m.gender === "Female" &&
                                m.state !== "remove"
                        )
                    ) {
                        if (checkParentLimit(members, family)) {
                            newRelationshipList.push(motherInLaw);
                        }
                    }
                } else if (family.parent === 4) {
                    if (
                        !members.find(
                            (m) =>
                                m.relationShip === "ParentInLaw" &&
                                m.state !== "remove"
                        )
                    ) {
                        if (
                            !members.find(
                                (m) =>
                                    m.relationShip === "Parent" &&
                                    m.gender === "Male" &&
                                    m.state !== "remove"
                            )
                        ) {
                            if (checkParentLimit(members, family)) {
                                newRelationshipList.push(father);
                            }
                        }

                        if (
                            !members.find(
                                (m) =>
                                    m.relationShip === "Parent" &&
                                    m.gender === "Female" &&
                                    m.state !== "remove"
                            )
                        ) {
                            if (checkParentLimit(members, family)) {
                                newRelationshipList.push(mother);
                            }
                        }
                    }
                    if (
                        !members.find(
                            (m) =>
                                m.relationShip === "Parent" &&
                                m.state !== "remove"
                        )
                    ) {
                        if (
                            !members.find(
                                (m) =>
                                    m.relationShip === "ParentInLaw" &&
                                    m.gender === "Male" &&
                                    m.state !== "remove"
                            )
                        ) {
                            if (checkParentLimit(members, family)) {
                                newRelationshipList.push(fatherInLaw);
                            }
                        }

                        if (
                            !members.find(
                                (m) =>
                                    m.relationShip === "ParentInLaw" &&
                                    m.gender === "Female" &&
                                    m.state !== "remove"
                            )
                        ) {
                            if (checkParentLimit(members, family)) {
                                newRelationshipList.push(motherInLaw);
                            }
                        }
                    }
                }
            }

            if (newRelationshipList.length === 0) {
                setError("No more members can be added");
                return;
            }

            setRelationshipList(newRelationshipList);
            // set relationShip based on member and map it to relationShip of newRelationshipList based on gendera and id

            setRelationShip(newRelationshipList[0].id || "");
        }
    }, [members, member]);

    const handleSubmit = (values: FormValues) => {
        let payload = member
            ? (JSON.parse(JSON.stringify(member)) as Member)
            : ({} as Member);
        payload["name"] = values.name || "";
        // set relationShip and gender depending on values.relationShip
        if (values.relationShip !== "Employee" && !member) {
            payload["relationShip"] =
                relationshipList.find((r) => r.id === values.relationShip)
                    ?.relationShip || "";
        }
        if (values.relationShip !== "Employee" && !member) {
            payload["gender"] =
                relationshipList.find((r) => r.id === values.relationShip)
                    ?.gender || "";
        }
        if (values.relationShip === "Employee" && values.gender) {
            payload["gender"] = values.gender;
        }
        payload["dob"] = changeDateFormat(
            getMemberDobType(values.dob.length),
            "yyyy-mm-dd",
            values.dob
        ).toString();
        if (payload.relationShip === "LiveInPartner") {
            payload.relationShip = "Spouse";
            payload["displayRelationship"] = "LiveInPartner";
        }
        onSubmit(payload, index);
    };

    // find member's dob type
    const getMemberDobType = (length: number) => {
        if (length === 11) {
            return "dd-mmm-yyyy";
        } else if (length === 10) {
            return "yyyy-mm-dd";
        }
        return "javascriptDateObject";
    };

    return (
        <div>
            <GenericModalContainer
                close={close}
                headingText={member ? "Edit Dependents" : "Add Dependents"}
            >
                {error ? (
                    <div className="error">
                        {/* error sign from mui and make it big */}
                        <Error fontSize="large" /> &nbsp; {error}
                    </div>
                ) : (
                    <Formik
                        initialValues={{
                            name: member?.name || "",
                            dob: member
                                ? changeDateFormat(
                                      "yyyy-mm-dd",
                                      "dd-mmm-yyyy",
                                      member?.dob
                                  ).toString()
                                : "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleSubmit({
                                ...values,
                                relationShip:
                                    member?.relationShip !== "Employee"
                                        ? relationShip
                                        : "Employee",
                                ...(member?.relationShip === "Employee" && {
                                    gender: gender,
                                }),
                            });
                            setSubmitting(false);
                        }}
                    >
                        {({}) => (
                            <Form>
                                <div className="elementContainer">
                                    <MyText
                                        label="Name"
                                        name="name"
                                        type="text"
                                        placeholder="Enter name"
                                        onChange={() => {}}
                                    />
                                    <MyText
                                        label="Date of Birth"
                                        name="dob"
                                        type="date"
                                        placeholder="Enter date of birth"
                                        onChange={() => {}}
                                    />
                                    {member?.relationShip !== "Employee" &&
                                        !member && (
                                            <Dropdown
                                                label="Relationship"
                                                name="relationShip"
                                                items={relationshipList}
                                                change={(val: string) => {
                                                    setRelationShip(val);
                                                }}
                                                defaultVal={relationShip}
                                            />
                                        )}
                                    {member?.relationShip === "Employee" && (
                                        <Dropdown
                                            label="Gender"
                                            name="gender"
                                            items={["Male", "Female"].map(
                                                (gen) => ({
                                                    id: gen,
                                                    name: gen,
                                                })
                                            )}
                                            change={(val: string) => {
                                                setGender(val);
                                            }}
                                            defaultVal={gender}
                                        />
                                    )}
                                </div>
                                <div className="buttons">
                                    <button className="btn simple primary">
                                        {member ? "Edit" : "Add"}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </GenericModalContainer>
        </div>
    );
};

export default AddMemberForm;
