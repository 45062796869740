import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Outlet } from "react-router-dom";
import NewTopbar from "../components/Topbar/NewTopbar";
import Topbar from "./../components/Topbar/Topbar";
import AuthorizedRoute from "../components/AuthorizedRoute";

const WrapperRoute = () => {
    return (
        <>
            <NewTopbar />
            <div className="wrapper">
                <div className="content-wrapper">
                    <div className="body-container">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WrapperRoute;
