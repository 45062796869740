import React from "react";
import Loader from "../Loader/Loader";
import { IconButton, Modal } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const CloseIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Modals_icons/Close.svg";

const GenericModalContainer = (props) => {
    return (
        <Modal open={true} onClose={props.close} className="modal-container">
            <div
                className={`main-modal delete-modal ${
                    props.addClass ? props.addClass : ""
                }`}
                style={{ ...props.styles }}
            >
                <Loader loader={props.loader}>
                    <div className="modal-header">
                        {props.backButton && (
                            <IconButton
                                onClick={() => {
                                    props.backButton();
                                }}
                                color="primary"
                                component="span"
                                style={{
                                    padding: "1rem 1rem",
                                    margin: "-1rem auto -1rem -1rem",
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        )}
                        <div className={`title ${props?.headingClassName}`}>
                            {props.headingText}
                        </div>
                        <span
                            className="close"
                            style={{ position: "relative", zIndex: 998 }}
                            onClick={props.close}
                        >
                            <img
                                src={CloseIcon}
                                onClick={props.close}
                                alt="close"
                            />
                        </span>
                    </div>
                    {props.children}
                </Loader>
            </div>
        </Modal>
    );
};

export default GenericModalContainer;
