import { AxiosResponse } from "axios";
import { signJwtAndRedirect } from "../functions";
import { JavaResponse } from "../../types";
import { LoginResponse } from "../../types/DTO/loginDTO";

export const redirect = (
    res: AxiosResponse<JavaResponse<LoginResponse>>,
    navigate: Function
) => {
    document.body.style.background = "#f6f6f6";
    let page = res.data.response.page;
    let uuid =
        res.data.response.uuid ||
        res.data.response.user.uuid ||
        res.data.response.lead?.uuid ||
        null;

    if (page === "additionalInfo") {
        signJwtAndRedirect("/additionalInfo");
    } else if (page === "estimate") {
        signJwtAndRedirect("/estimate");
    } else if (
        page === "memberDataUpload" ||
        page === "companyInfo" ||
        page === "invoice"
    ) {
        if (page === "memberDataUpload") {
            signJwtAndRedirect(`/quote/${uuid}`);
        } else if (page === "companyInfo") {
            signJwtAndRedirect(`/quote/${uuid}/company`);
        } else {
            signJwtAndRedirect(`/quote/${uuid}/invoice`);
        }
    } else if (
        page === "memberData" &&
        (window.location.pathname.split("/")[2] === "company" ||
            window.location.pathname === "/")
    ) {
        navigate("/home");
    } else if (
        page === "leadView" &&
        window.location.pathname !== "/leads" &&
        window.location.pathname.split("/")[1] !== "unsubscribe" &&
        window.location.pathname.split("/")[1] !== "carePaymentComplete" &&
        window.location.pathname !== "/members"
    ) {
        if (res.data.response.user.isMigrated) {
            window.location.pathname = "/crm";
        } else {
            navigate("/leads");
        }
    } else if (page === "manual") {
        navigate("/home");
    } else if (page === "anchorPrice") {
        navigate("/home");
    } else if (page === "nonEmployeeFlow") {
        navigate("/home");
    } else if (page === "newEstimate") {
        signJwtAndRedirect("/newLead");
    }
};
