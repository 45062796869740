import React from "react";
import ReactDOM from "react-dom";
import reducer, { initialState } from "../src/util//reducer";
import { StateProvider } from "../src/util/stateProvider";
import App from "./App";
import "./assets/scss/index.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./views/Microsoft/authConfig";
import ReduxProvider from "./provider";
import ReactGA4 from "react-ga4";
import { QueryClient, QueryClientProvider } from "react-query";

const msalInstance = new PublicClientApplication(
    msalConfig(window.location.hostname)
);

if (process.env.REACT_APP_GA4_ID) {
    ReactGA4.initialize(process.env.REACT_APP_GA4_ID);
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
}

// show/hide console.log statements
if (process.env.REACT_APP_ENV === "/dashboard") {
    console.log = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
          staleTime: 60000
        },
      },
});

root.render(
    <Router basename={process.env.NODE_ENV === "production" ? "" : ""}>
        <QueryClientProvider client={queryClient}>
            <StateProvider initialState={initialState} reducer={reducer}>
                <MsalProvider instance={msalInstance}>
                    <ReduxProvider>
                        <App />
                    </ReduxProvider>
                </MsalProvider>
            </StateProvider>
        </QueryClientProvider>
    </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
