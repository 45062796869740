import { useState, useEffect } from "react";
import "./CareStatus.scss";
import SuccessIcon from "../../../assets/images/icons/successsnack.svg";
import FailureIcon from "../../../assets/images/icons/failedsnack.svg";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import advService from "../../../util/advService";
import service from "../../../util/axiosConfig";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../../types";
const illustrationSuper =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Super_TopUp/superIllustration.png";
const PendingIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/assets/images/icons/Pending.png";

const PaymentStatusText = {
    Loading: {
        icon: PendingIcon,
        title: "Loading...",
        subText: "Please wait",
    },
    Pending: {
        icon: PendingIcon,
        title: "Payment pending",
        subText: "Please wait for sometime. We'll get back to you",
    },
    Success: {
        icon: SuccessIcon,
        title: "Payment successful",
        subText:
            "Congratulations, your policy {policyNumber} has been issued. The policy document will be sent to your email within 24hrs.",
    },
    Failed: {
        icon: FailureIcon,
        title: "Payment unsuccessful",
        subText:
            "We are validating your payment for proposal number {proposalNumber} if your payment has been deducted or else you can retry again.",
    },
};

const CareStatus = () => {
    const [status, setStatus] = useState(PaymentStatusText.Loading);
    const [policyNumber, setPolicyNumber] = useState("");
    const [timer, setTimer] = useState(10);
    const [showTimer, setShowTimer] = useState(false);
    const { proposalNumber, payStatus } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (proposalNumber) {
            // service
            //     .post(
            //         `https://app.imt.care/api/node/care/paymentComplete/${proposalNumber}`,
            //         {}
            //     )
            //     .then((res) => {
            //         // if (res.data.message === "Success") {
            //         if (res.data.response.success === 1) {
            //             setStatus(PaymentStatusText.Success);
            //             setPolicyNumber(res.data.response.policyNum);

            //         } else {
            //             setStatus(PaymentStatusText.Failed);
            //             const intervalId = setInterval(() => {
            //                 if (timer > 0) {
            //                     setTimer((prevTimer) => prevTimer - 1);
            //                 }
            //             }, 1000);
            //             setShowTimer(true);
            //         }
            // setTimeout(() => {
            //     (window as any)?.ReactNativeWebView?.postMessage(
            //         JSON.stringify({
            //             tag: "GoBack",
            //             user: null,
            //             token: null,
            //         })
            //     );
            //     navigate("/home");
            // }, 10000);
            //     })
            //     .catch((err) => {
            //         setStatus(PaymentStatusText.Failed);
            //         setTimeout(() => {
            //             (window as any)?.ReactNativeWebView?.postMessage(
            //                 JSON.stringify({
            //                     tag: "GoBack",
            //                     user: null,
            //                     token: null,
            //                 })
            //             );
            //             navigate("/home");
            //         }, 10000);
            //     });
            const intervalId = setInterval(() => {
                if (timer > 0) {
                    setTimer((prevTimer) => prevTimer - 1);
                }
            }, 1000);
            setShowTimer(true);
            if (payStatus === "true") {
                setStatus(PaymentStatusText.Success);
                setPolicyNumber(proposalNumber);
                setTimeout(() => {
                    (window as any)?.ReactNativeWebView?.postMessage(
                        JSON.stringify({
                            tag: "GoBack",
                            user: null,
                            token: null,
                        })
                    );
                    navigate("/home");
                }, 10000);
            } else {
                setStatus(PaymentStatusText.Failed);
                setPolicyNumber(proposalNumber);
                setTimeout(() => {
                    (window as any)?.ReactNativeWebView?.postMessage(
                        JSON.stringify({
                            tag: "GoBack",
                            user: null,
                            token: null,
                        })
                    );
                    navigate("/home");
                }, 10000);
            }
        }
    }, [proposalNumber]);

    return (
        <div className="carepaymentstatuspage">
            <div className="carepaymentstatuspage_banner">
                <img src={illustrationSuper} alt="" className="ml-0" />
            </div>
            <div className="carepaymentstatuspage_body mt30">
                <img
                    src={status.icon}
                    alt="status icon"
                    width={60}
                    height={60}
                />
                <div className="carepaymentstatuspage_text mt10">
                    <h2>{status.title}</h2>
                    {status === PaymentStatusText.Pending ? (
                        <p>{status.subText}</p>
                    ) : null}
                    {status === PaymentStatusText.Success && policyNumber ? (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: status.subText.replace(
                                    "{policyNumber}",
                                    `<strong>${policyNumber}</strong>`
                                ),
                            }}
                        ></p>
                    ) : null}
                    {status === PaymentStatusText.Failed && proposalNumber ? (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: status.subText.replace(
                                    "{proposalNumber}",
                                    `<strong>${policyNumber}</strong>`
                                ),
                            }}
                        ></p>
                    ) : null}
                    {showTimer && (
                        <p className="mt5">
                            You will be redirected to homepage in {timer}{" "}
                            seconds.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CareStatus;
