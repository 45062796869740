import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import "./alerts.scss";
import successIcon from "../assets/images/alert_icons/Success.svg"
import errorIcon from "../assets/images/alert_icons/Error.svg"
import infoIcon from "../assets/images/alert_icons/informational.svg"
import clearIcon from "../assets/images/alert_icons/Clear.svg"

export default alert = (message, type, duration = 3000, alertObj) => {
    console.log("update Success 1:", message, type, duration, alertObj);
    const settings = {
        position: "bottom-center",
        autoClose: duration,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: <img src={clearIcon} alt="Clear" />,
        theme: "colored",
    };
    switch (type) {
        case "info":
            toast.info(message, {...settings,icon:({ theme, type }) => <img src={infoIcon} alt="info" />});
            break;
        case "success":
            toast.success(message, {...settings,icon:({ theme, type }) => <img src={successIcon} alt="success" /> });
            break;
        case "warning":
            toast.warn(message, {...settings,icon:({ theme, type }) => <img src={errorIcon} alt="warn" /> });
            break;
        case "error":
            toast.error(message, {...settings,icon:({theme,type})=><img src={errorIcon} alt="error" />});
            break;
        //    case "promise":
        //      toast.promise(promise, message);
        //      break;
        case "loading":
            return toast.loading(message, settings);
        case "loadingSuccess":
            console.log("update Success", message, type, duration, alertObj);
            toast.update(alertObj, {
                ...settings,
                render: message,
                type: "success",
                isLoading: false,
                autoClose: false,
                icon:({ theme, type }) => <img src={successIcon} alt="success" />
            });
            setTimeout(() => toast.dismiss(alertObj), duration);

            break;
        case "loadingWarning":
            toast.update(alertObj, {
                ...settings,
                render: message,
                type: "warn",
                isLoading: false,
                autoClose: false,
                icon:({ theme, type }) => <img src={errorIcon} alt="warn" />
            });

            setTimeout(() => toast.dismiss(alertObj), duration);

            break;
        case "loadingError":
            toast.update(alertObj, {
                ...settings,
                render: message,
                type: "error",
                isLoading: false,
                autoClose: false,
                icon:({theme,type})=><img src={errorIcon} alt="error" />
            });

            setTimeout(() => toast.dismiss(alertObj), duration);
            
            break;
        case "dismiss":
            //toast.dismiss();
            setTimeout(() => toast.dismiss(alertObj), duration);
            break;
        default:
            toast(message, settings);
    }
};
