import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useStateValue } from "./stateProvider";

const useTheme = () => {
    const [{ subDomainInfo }] = useStateValue();

    const theme = createTheme({
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: "outlined",
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: "outlined",
                },
            },
        },
        palette: {
            neutral: {
                main: "#d6d6d6",
            },
            primary: {
                main: subDomainInfo?.theme?.primary || "#4f3dd1",
            },
            error: {
                main: "#e94e4e",
            },
        },
        typography: {
            fontFamily: "Poppins, Arial, sans-serif",
        },
    });

    return theme;
};

export default useTheme;
