// Create a image compressor takes url and return a promise of compressed image

const imageCompressor = (
    url: string,
    config: { height?: number; width?: number; quality: number }
):Promise<File | Blob> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
            let width = img.width;
            let height = img.height;
            let newWidth = 0;
            let newHeight = 0;
            if(config.height && config.width) {
                const aspectRatio = width / height;
                if (width > config.width || height > config.height) {
                    if (width > height) {
                        newWidth = config.width;
                        newHeight = config.width / aspectRatio;
                    } else {
                        newHeight = config.height;
                        newWidth = config.height * aspectRatio;
                    }
                } else {
                    newWidth = width;
                    newHeight = height;
                }
            }
            
            const elem = document.createElement("canvas");
            elem.width = config.width ? newWidth : width;
            elem.height = config.height ? newHeight : height;
            const ctx = elem.getContext("2d");

            ctx!.drawImage(img, 0, 0, elem.width, elem.height);
            ctx!.canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        reject(new Error("Canvas is empty"));
                        return;
                    }
                    resolve(blob);
                },
                "image/jpeg",
                config.quality
            );
        };
        img.onerror = (error) => reject(error);
    });
};

export default imageCompressor;