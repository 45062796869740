import React, { useEffect } from "react";

//Routing
import { useNavigate } from "react-router";

const NotFound = ({ status, redirectPath = null }) => {
    const navigate = useNavigate();
    console.log(typeof status);
    useEffect(() => {
        const defaultRoutes = {
            ADMIN: "/leads",
            BROKER: "/leads",
            EMPLOYEE: "/home",
            EMPLOYER: "/home",
        };
        if (status === 404) {
            const userType = JSON.parse(
                sessionStorage.getItem("user")
            )?.userType;
            if (!userType) setTimeout(() => navigate("/login"), 3000);
            else setTimeout(() => navigate(defaultRoutes[userType]), 3000);
        } else setTimeout(() => navigate(redirectPath), 3000);
    }, [status, navigate, redirectPath]);
    return <h2>{status} Not Found</h2>;
};

export default NotFound;
